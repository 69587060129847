import { Modal } from "bootstrap";

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getOrCreateInstance(modalEl);
  myModal.show();
};

const removeModals = (): void => {
  if (document.querySelectorAll(".modal.fade.show").length) {
    document.querySelectorAll(".modal.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const removeModalBackdrops = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const removeTooltips = (): void => {
  if (document.querySelectorAll(".tooltip[role='tooltip']").length) {
    document.querySelectorAll(".tooltip[role='tooltip']").forEach((item) => {
      item.remove();
    });
  }
};

const removeModalDependecyClassesAndStyles = (): void => {
  const body = document.getElementById("sx_body");
  if (body && body.classList.contains("modal-open")) {
    body.classList.remove("modal-open");
    body.removeAttribute("style");
  }
};

const removeMoshaNotifications = (): void => {
  if (document.querySelectorAll(".mosha__toast").length) {
    document.querySelectorAll(".mosha__toast").forEach((item) => {
      item.classList.add("hidden");
    });
  }
};

export {
  removeModals,
  removeModalBackdrops,
  removeModalDependecyClassesAndStyles,
  removeMoshaNotifications,
  removeTooltips,
  showModal,
  hideModal,
};
