<!-- eslint-disable vue/no-unused-vars -->
<template>
  <input
    type="hidden"
    class="hidden"
    :name="`${gridId}-CustomSaveOption`"
    :id="`${gridId}-CustomSaveOption`"
    :value="false"
  />
  <template v-if="!isLookupRelationTable && pageData">
    <input
      type="hidden"
      class="hidden"
      id="FilterId"
      v-if="pageData.filter"
      :value="pageData.filter.publicId"
    />
    <PageFilter
      v-if="gridShow && pageData.isPageCriteriaDraw"
      :gridId="gridId"
      :pageData="pageData"
      :fields="pageAllFields"
    />
    <InstantFilterModal
      ref="instantFilterModal"
      :pageData="pageData"
      v-if="
        gridShow && pageData.filter && pageData.filter.isInstantFilterActive
      "
    />
  </template>
  <SetLoader
    v-if="!gridShow && !isLookupRelationTable && responseError.length == 0"
  />
  <div
    class="dx-viewport sx-scrollable"
    :id="`${gridId}-parent`"
    :class="{
      'mt-2': !isLookupRelationTable,
    }"
  >
    <AlertBox classes="mb-2" type="warning" :messages="responseError" />
    <div
      v-on:scroll.passive="sxScrollHandle"
      :id="`${gridId}-double-scroll-wrapper`"
      :class="{
        hidden: !isDoubleScrollNeeded || !gridShow || $isMobile(),
        'sx-double-scroll-table': isLookupRelationTable,
      }"
      class="sx-double-scroll-wrapper"
    >
      <div class="double-scroll"></div>
    </div>
    <DxDataGrid
      :ref="gridRefName"
      :id="gridId"
      :visible="gridShow"
      :data-source="dataSource"
      :word-wrap-enabled="true"
      :allow-column-reordering="!$isMobile()"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      :column-hiding-enabled="!isNotDrawAsMobile"
      :allow-column-resizing="true"
      :column-resizing-mode="columnResizingMode"
      :column-auto-width="false"
      :focused-row-enabled="true"
      :auto-navigate-to-focused-row="false"
      :repaint-changes-only="rePaintChangesOnly"
      :hover-state-enabled="true"
      :remote-operations="true"
      :cache-enabled="false"
      :noDataText="noDataText"
      :selection="selectionMode"
      @rowDblClick="rowDblClick"
      @cellDblClick="cellDblClick"
      @row-prepared="onRowPrepared"
      @cell-prepared="onCellPrepared"
      @initialized="initialized"
      @contentReady="contentReady"
      @option-changed="onOptionChanged"
      @init-new-row="initNewRow"
      @edit-canceled="onEditCanceled"
      @editing-start="onEditingStart"
      @row-inserted="rowInserted"
      @row-inserting="rowInserting"
      @row-updated="rowUpdated"
      @saving="onSaving"
      @row-validating="onRowValidating"
      @key-down="onKeyDown"
    >
      <DxScrolling mode="standard" :use-native="false" show-scrollbar="never" />
      <DxKeyboardNavigation :enabled="true" />
      <DxSorting mode="multiple" />
      <DxColumnChooser :enabled="true" mode="select">
        <DxPosition
          v-if="!isLookupRelationTable"
          my="right top"
          at="right bottom"
          of=".dx-datagrid-column-chooser-button"
        />
        <DxColumnChooserSearch :enabled="true" />
      </DxColumnChooser>
      <DxSearchPanel
        v-if="
          pageData && pageData.filter && pageData.filter.isFilterSearchBoxActive
        "
        :visible="
          pageData && pageData.filter && pageData.filter.isFilterSearchBoxActive
        "
        :highlight-case-sensitive="true"
        :search-visible-columns-only="true"
      />
      <DxMasterDetail
        v-if="pageData && pageData.filter && pageData.filter.isRelationDetail"
        :enabled="
          pageData && pageData.filter && pageData.filter.isRelationDetail
        "
        template="masterDetailTemplate"
      />
      <DxPager
        :visible="true"
        :display-mode="pagerDisplayMode"
        :allowed-page-sizes="pageSizes"
        :show-page-size-selector="true"
        :show-navigation-buttons="true"
        :show-info="true"
      />
      <DxPaging :visible="true" :page-size="pageSize" />
      <DxEditing
        :allow-updating="editSettings.allowEditing"
        :allow-deleting="true"
        :allow-adding="editSettings.allowAdding"
        :mode="editingMode"
      >
        <DxTexts confirm-delete-message="" />
      </DxEditing>

      <DxToolbar>
        <DxItem
          location="after"
          :visible="dxRowIsEditing"
          template="saveRowButtonTemplate"
        />
        <DxItem
          location="after"
          :visible="dxRowIsEditing"
          template="cancelRowButtonTemplate"
        />
        <DxItem
          v-if="isLookupRelationTable"
          location="before"
          template="lookupRelationInformationTemplate"
        />
        <DxItem
          location="after"
          :visible="
            isLookupRelationTable &&
            table &&
            table.viewFilter &&
            table.viewFilter.isNewButtonActive
          "
          locateInMenu="auto"
          :disabled="dxRowIsEditing"
          template="addRowButtonTemplate"
        />
        <DxItem
          :visible="editSettings.allowEditing && !$isMobile()"
          location="after"
          locateInMenu="auto"
          template="shortcutInformationTemplate"
        />
        <DxItem
          :visible="!$isMobile()"
          location="after"
          locateInMenu="auto"
          template="fontSizeChangerTemplate"
        />
        <DxItem
          location="after"
          locateInMenu="auto"
          template="refreshTemplate"
        />
        <DxItem
          name="columnChooserButton"
          locateInMenu="auto"
          :disabled="dxRowIsEditing"
        />

        <DxItem
          location="after"
          :disabled="dxRowIsEditing"
          locateInMenu="auto"
          :visible="
            isLookupRelationTable &&
            table &&
            table.viewFilter &&
            table.viewFilter.isBulkProcessButtonActive
          "
          template="bulkProcessButtonTemplate"
        />

        <DxItem
          v-if="isLookupRelationTable"
          location="after"
          :disabled="dxRowIsEditing"
          locateInMenu="auto"
          :visible="
            isLookupRelationTable &&
            table &&
            table.viewFilter &&
            table.viewFilter.isEditInlineButtonActive
          "
          template="editInlineButtonTemplate"
        />

        <DxItem
          name="searchPanel"
          locateInMenu="auto"
          :disabled="dxRowIsEditing"
        />
      </DxToolbar>

      <!-- Toolbar Templates -->
      <template #addRowButtonTemplate>
        <DxButtonToolbar icon="plus" class="dx-grid-add-row" @click="addRow" />
      </template>

      <template #saveRowButtonTemplate>
        <button
          type="button"
          class="btn btn-success dx-grid-record-save btn-icon"
          @click="saveRow"
        >
          <i class="fas fa-save"></i>
        </button>
      </template>

      <template #bulkProcessButtonTemplate>
        <template v-if="$isMobile()">
          <button
            v-for="(bulkAction, i) in bulkActionTypes"
            :key="i"
            v-bind="bulkAction.attributes"
            type="button"
            class="btn btn-primary w-100"
            :class="{
              'mb-1': i > 0,
            }"
          >
            <i class="fas fa-cogs"></i>
            {{ bulkAction.value }}
          </button>
        </template>
        <Dropdown
          v-else
          routeName="bulkAction"
          :localData="bulkActionTypes"
          :buttonName="
            $t(
              'Components.ActionButtons.BulkAction',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
          buttonIcon="fas fa-cogs"
          buttonClasses="btn-light"
          groupClasses=""
          :isDisabled="dxRowIsEditing"
          @itemSelection="bulkActionSelection"
        />
      </template>

      <template #editInlineButtonTemplate>
        <ToggleButton
          classes="w-160"
          :prefix="gridId"
          :selected="!editSettings.allowAdding"
          :first="
            $t(
              'BaseModelFields.DetailUpdate',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
          :second="
            $t(
              'BaseModelFields.InLineUpdate',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
          @off="inlineEditToggleOff"
          @on="inlineEditToggleOn"
        />
      </template>

      <template #cancelRowButtonTemplate>
        <button
          type="button"
          class="btn btn-danger dx-grid-cancel-row btn-icon"
          @click="cancelRow"
        >
          <i class="fas fa-times-circle"></i>
        </button>
      </template>

      <template #lookupRelationInformationTemplate>
        <button
          type="button"
          class="btn btn-default btn-dx-grid-title text-capitalize btn-sm btn-no-custom-style fs-5 h-100"
          :style="{
            color: String.isNullOrWhiteSpace(element.fontColor)
              ? ''
              : element.fontColor + '!important',
          }"
          @click="tableToggleDisplay"
        >
          {{ table.name }}
        </button>
      </template>

      <template #shortcutInformationTemplate>
        <div
          class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-template-wrapper dx-item-content dx-toolbar-item-content"
          role="button"
          tabindex="0"
          data-bs-toggle="modal"
          :data-bs-target="`#shortcutInformationModal_${gridId}`"
        >
          <div class="dx-button-content">
            <i class="dx-icon dx-icon-info"></i>
          </div>
        </div>
      </template>

      <template #fontSizeChangerTemplate>
        <DxButtonToolbar icon="fontsize" @click="fontSizeChange" />
      </template>

      <template #refreshTemplate>
        <div
          class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-template-wrapper dx-item-content dx-toolbar-item-content"
          :class="{
            'dx-state-disabled': dxRowIsEditing,
          }"
          role="button"
          aria-label="refresh"
          @click="refreshDataGrid"
          tabindex="0"
        >
          <div class="dx-button-content">
            <i
              class="dx-icon dx-icon-refresh"
              :class="{
                'rotate-animation rotate-animation-fast': gridDataIsLoading,
              }"
            ></i>
          </div>
        </div>
      </template>
      <!-- End Toolbar Templates -->

      <DxColumn
        v-for="(column, i) in columns"
        :key="column.uId"
        :width="column.width > 0 ? `${column.width}px` : 'auto'"
        :data-field="column.formulaName"
        :data-type="column.type"
        :caption="column.text"
        :format="column.format"
        :cell-template="column.template"
        :alignment="column.textAlign"
        :sort-index="column.sortIndex"
        :sort-order="column.sortOrder"
        :allowEditing="column.allowEditing"
        :allowSorting="column.allowSorting"
        :edit-cell-template="column.editCellTemplate"
        :header-cell-template="column.headerCellTemplate"
        :formItem="{
          visible: ![this.serialNoKey, this.primaryKey].includes(
            column.formulaName
          ),
        }"
        v-model:visible="column.visible"
      >
        <template v-if="column.fieldModel && !$isMobile()">
          <DxRequiredRule
            v-if="
              (column.fieldModel.isRequired || column.required) &&
              column.fieldModel.fieldType != 'AutoId' &&
              !column.fieldModel.fieldType.includes('Formula') &&
              column.fieldModel.isActive
            "
          />
          <DxEmailRule v-if="column.fieldModel.fieldType == 'Email'" />
          <DxStringLengthRule
            :max="column.fieldModel.length"
            v-if="
              column.fieldModel.fieldType == 'Text' &&
              column.fieldModel.length > 0
            "
          />
        </template>
      </DxColumn>

      <DxSummary>
        <DxTotalItem
          v-for="(aggregate, i) in aggregates"
          :key="i"
          :column="aggregate.column"
          :summary-type="aggregate.type"
          :show-in-column="aggregate.showInColumn"
          :alignment="aggregate.alignment"
          :display-format="aggregate.displayFormat"
        />
      </DxSummary>

      <!-- Header Cell Templates Start -->
      <template
        v-for="(template, i) in headerCellTemplates"
        :key="template.id"
        #[template.id]="{ data }"
      >
        {{ data.column.caption }}
        <template v-if="!String.isNullOrWhiteSpace(template.field.helpText)">
          <i
            class="bi bi-question-circle-fill ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            :data-bs-original-title="`<div class='sx-grid-header-tooltip'>${template.field.helpText}</div>`"
          ></i>
        </template>
        <template
          v-if="
            !isLookupRelationTable &&
            gridShow &&
            pageData.filter &&
            pageData.filter.isInstantFilterActive &&
            !template.parent.$root.fieldTypes.file.includes(
              template.field.fieldType
            )
          "
        >
          <i
            class="fa fa-filter text-end ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-html="true"
            @click="filterHeaderField($event, template.field)"
            :data-bs-original-title="`<div class='sx-grid-header-tooltip'>${$t(
              'Components.InstantFilterModal.ApplyFieldFilter',
              {},
              { locale: this.$store.state.activeLang }
            )}</div>`"
          ></i>
        </template>
      </template>
      <!-- Header Cell Templates End -->

      <template #serialNoTemplate="{ data: { data } }">
        <template v-if="String.isNullOrWhiteSpace(data[primaryKey])">
          <span></span>
        </template>
        <template
          v-else-if="
            pageData && pageData.filter && pageData.filter.isDetailButtonActive
          "
        >
          <router-link
            :to="`/set/detail/${
              isLookupRelationTable
                ? table.lookupObjectUrlName
                : $route.params.key
            }/${data[primaryKey]}`"
            @click="serialNoClicked"
            :target="isLookupRelationTable ? '_blank' : '_self'"
          >
            {{
              this.grid.pageIndex() * this.grid.pageSize() +
              parseInt(data["SERIALNO"])
            }}
          </router-link>
        </template>
        <template v-else>
          <span>
            {{
              this.grid.pageIndex() * this.grid.pageSize() +
              parseInt(data["SERIALNO"])
            }}</span
          >
        </template>
      </template>

      <template
        v-for="(template, i) in masterDetailTemplates"
        :key="template.id"
        #[template.id]="{ data: { data } }"
      >
        <MasterDetailGrid
          :parentRecord="{
            ...data,
            ...pageData.filter,
          }"
          :parentSelf="template.self"
        />
      </template>

      <!-- Inline Edit Cell Templates By Field Type Start -->
      <template
        v-for="(template, i) in editCellTemplates"
        :key="template.id"
        #[template.id]="{ data: { data } }"
      >
        <div v-bind="template.cell.htmlAttributes">
          <template
            v-if="template.hiddenFieldControllingDependencies.length > 0"
          >
            <template
              v-for="(
                hiddenTemplate, i
              ) in template.hiddenFieldControllingDependencies"
              :key="hiddenTemplate.id"
            >
              <div
                class="hidden column-hidden-controlling-dependency-field"
                v-bind="hiddenTemplate.cell.htmlAttributes"
              >
                <template v-if="hiddenTemplate.field.fieldType == 'Lookup'">
                  <PagedSelectList
                    :table="table"
                    :rowData="data"
                    :template="hiddenTemplate"
                    :value="
                      editCellFieldValue(hiddenTemplate.field, data, 'text')
                    "
                    :valueId="
                      editCellFieldValue(hiddenTemplate.field, data, 'id')
                    "
                  />
                </template>
              </div>
            </template>
          </template>
          <template
            v-if="
              this.$root.fieldTypes.text.includes(template.field.fieldType) ||
              this.$root.fieldTypes.html.includes(template.field.fieldType)
            "
          >
            <TextBox
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.checkbox.includes(template.field.fieldType)
            "
          >
            <CheckBox
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.number.includes(template.field.fieldType)
            "
          >
            <NumericTextBox
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.datetime.includes(template.field.fieldType)
            "
          >
            <DatePicker
              type="datetime"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="
                dateFormating(
                  'datetime',
                  editCellFieldValue(template.field, data)
                )
              "
              :template="template"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.date.includes(template.field.fieldType)
            "
          >
            <DatePicker
              type="date"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="
                dateFormating('date', editCellFieldValue(template.field, data))
              "
              :template="template"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.time.includes(template.field.fieldType)
            "
          >
            <DatePicker
              type="time"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="
                dateFormating('time', editCellFieldValue(template.field, data))
              "
              :template="template"
            />
          </template>
          <template
            v-else-if="
              template.field.fieldType == 'SelectList' && template.field.isRadio
            "
          >
            <RadioSelectList
              :items="
                radioSelectListValues.find(
                  (f) => f.fieldPublicId == template.field.publicId
                )['items']
              "
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :template="template"
              :rowData="data"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-if="
              template.field.fieldType == 'SelectList' &&
              !template.field.isRadio
            "
          >
            <SelectList
              :template="template"
              :rowData="data"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-else-if="
              template.field.fieldType != 'SelectList' &&
              this.$root.fieldTypes.select.includes(template.field.fieldType)
            "
          >
            <PagedSelectList
              :table="table"
              :rowData="data"
              :template="template"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.textarea.includes(template.field.fieldType)
            "
          >
            <DxTextArea
              @value-changed="
                editCellTemplateItemValueChangedForDxComponents(
                  $event,
                  template
                )
              "
              :input-attr="{ id: template.id, name: template.id }"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            >
            </DxTextArea>
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.file.includes(template.field.fieldType)
            "
          >
            <FileInputWithEdit
              v-if="data[primaryKey]"
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="data[template.field.formulaName]"
              :valueId="
                data[
                  template.field.formulaName +
                    template.parent.$fieldPublicIdFormulaExtension
                ]
              "
            />
            <FileInput
              v-else
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data, 'text')"
              :valueId="editCellFieldValue(template.field, data, 'id')"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.phone.includes(template.field.fieldType)
            "
          >
            <PhoneInput
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
          <template
            v-else-if="
              this.$root.fieldTypes.spatial.includes(template.field.fieldType)
            "
          >
            <Spatial
              :template="template"
              :rowData="data"
              :disabled="editCellFieldIsDisabled(template.field, data)"
              :value="editCellFieldValue(template.field, data)"
            />
          </template>
        </div>
        <template v-if="template.hiddenFieldDependencies.length > 0">
          <template
            v-for="(hiddenTemplate, i) in template.hiddenFieldDependencies"
            :key="hiddenTemplate.id"
          >
            <div
              class="hidden column-hidden-dependency-field"
              v-bind="hiddenTemplate.cell.htmlAttributes"
            >
              <template v-if="hiddenTemplate.field.fieldType == 'Lookup'">
                <PagedSelectList
                  :table="table"
                  :rowData="data"
                  :template="hiddenTemplate"
                  :value="
                    editCellFieldValue(hiddenTemplate.field, data, 'text')
                  "
                  :valueId="
                    editCellFieldValue(hiddenTemplate.field, data, 'id')
                  "
                />
              </template>
            </div>
          </template>
        </template>
      </template>
      <!-- Inline Edit Cell Templates By Field Type End -->

      <!-- Action Buttons Start -->
      <template
        v-if="
          customActionButtons.length > 0 || devExpressActionButtons.length > 0
        "
      >
        <DxColumn
          type="buttons"
          :caption="
            this.$t(
              'BaseModelFields.Commands',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
        >
          <DxButton
            v-for="(button, i) in devExpressActionButtons"
            :key="button.uId"
            :name="button.name"
            :template="button.uId"
          >
          </DxButton>
          <DxButton
            @click="customActionButtonClick($event, button)"
            v-for="(button, i) in customActionButtons"
            :key="button.uId"
            :visible="isVisibleCustomActionButton"
            :template="button.uId"
          >
          </DxButton>
        </DxColumn>
      </template>

      <template
        v-for="(button, i) in devExpressActionButtons"
        :key="button.uId"
        #[button.uId]
      >
        <button
          type="button"
          class="btn btn-dx-system-button"
          :class="button.cssClass"
        >
          <template v-if="!String.isNullOrWhiteSpace(button.iconClass)">
            <i
              v-if="String.isNullOrWhiteSpace(button.iconColor)"
              :class="button.iconClass"
            ></i>
            <i
              v-else
              :style="`color:${button.iconColor} !important`"
              :class="button.iconClass"
            ></i>
            {{ button.label }}
          </template>
          <template v-else>
            {{ button.label }}
          </template>
        </button>
      </template>

      <template
        v-for="(button, i) in customActionButtons"
        :key="button.uId"
        #[button.uId]="{ data: { data } }"
      >
        <button
          v-if="button.type === 'button'"
          type="button"
          class="btn btn-sx-custom-button"
          :class="button.cssClass"
          v-bind="button.attributes"
        >
          <template v-if="!String.isNullOrWhiteSpace(button.iconClass)">
            <span
              v-bind="{
                'data-bs-toggle': $isMobile() ? '' : 'tooltip',
              }"
              :class="{
                'me-1': $isMobile(),
              }"
              data-bs-html="true"
              data-bs-placement="top"
              :data-bs-title="button.title"
            >
              <i
                v-if="String.isNullOrWhiteSpace(button.iconColor)"
                :class="button.iconClass"
              ></i>
              <i
                v-else
                :style="`color:${button.iconColor} !important`"
                :class="button.iconClass"
              ></i>
            </span>
            <template v-if="button.label">
              {{ button.label }}
            </template>
          </template>
          <template v-if="String.isNullOrWhiteSpace(button.iconClass)">
            {{ button.title }}
          </template>
        </button>
        <a
          v-else-if="button.type === 'link'"
          :href="`${button.routeUrl}/${
            button.appendRowPublicId ? data[this.primaryKey] : ''
          }`"
          class="btn btn-sx-custom-link"
          :class="button.cssClass"
          v-bind="button.attributes"
        >
          <i
            :class="button.iconClass"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :data-bs-title="button.title"
          ></i>
          {{ button.label }}
        </a>
      </template>
      <!-- Action Buttons End -->
    </DxDataGrid>
  </div>
  <InlineErrorModal
    :id="gridId"
    :informations="inlineInformations"
    :inlineErrors="inlineErrors"
    :inlineWarnings="inlineWarnings"
    :businessRuleResponse="businessRuleResponse"
  />
  <CellEditModal
    ref="cellEditModal"
    :id="gridId"
    :selectedRowIndex="selectedRowIndex"
    :selectedCellIndex="selectedCellIndex"
    :selectedRecordCellField="selectedRecordCellField"
    :selectedRowData="selectedRowData"
    @updatedCell="updatedCell"
  />
  <DeleteModal
    :id="gridId"
    :deleteRecordId="deleteRecordId"
    :customObjectKey="
      isLookupRelationTable ? table.lookupObjectUrlName : $route.params.key
    "
    :relationalRecordDeleteCustomObjectNames="
      pageData &&
      pageData.customObject &&
      pageData.customObject.relationalRecordDeleteCustomObjectNames
    "
    @deleteAfterCallMethod="deletedRow"
  />
  <BulkUpdateModal
    ref="bulkUpdateModal"
    v-if="
      gridShow &&
      ((pageData &&
        pageData.filter &&
        pageData.filter.isBulkProcessButtonActive) ||
        (table &&
          table.viewFilter &&
          table.viewFilter.isBulkProcessButtonActive))
    "
    :id="gridId"
    :table="table"
    :isTable="isLookupRelationTable"
    :pageData="pageData"
  />
  <ShortcutInformationModal
    v-if="pageData"
    :id="gridId"
    :table="table"
    :viewFilter="pageData.filter"
  />
</template>
<script>
import PagedSelectList from "@/components/devexpress/grid/editing/inline/PagedSelectList.vue";
import SelectList from "@/components/devexpress/grid/editing/inline/SelectList.vue";
import RadioSelectList from "@/components/devexpress/grid/editing/inline/RadioSelectList.vue";
import FileInput from "@/components/devexpress/grid/editing/inline/FileInput.vue";
import FileInputWithEdit from "@/components/devexpress/grid/editing/inline/FileInputWithEdit.vue";
import PhoneInput from "@/components/devexpress/grid/editing/inline/PhoneInput.vue";
import NumericTextBox from "@/components/devexpress/grid/editing/inline/NumericTextBox.vue";
import TextBox from "@/components/devexpress/grid/editing/inline/TextBox.vue";
import CheckBox from "@/components/devexpress/grid/editing/inline/CheckBox.vue";
import DatePicker from "@/components/devexpress/grid/editing/inline/DatePickerByType.vue";
import Spatial from "@/components/devexpress/grid/editing/inline/Spatial.vue";

import PageFilter from "@/components/custom/set-pages/PageFilter.vue";
import InstantFilterModal from "@/components/custom/set-pages/InstantFilterModal.vue";
import BulkUpdateModal from "@/components/custom/set-pages/BulkUpdateModal.vue";
import InlineErrorModal from "@/components/custom/set-pages/InlineErrorModal.vue";
import CellEditModal from "@/components/devexpress/grid/editing/modal/CellEditModal.vue";
import ShortcutInformationModal from "@/components/devexpress/grid/information/ShortcutModal";

//devexpress plugins
import DxButtonToolbar from "devextreme-vue/button";
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxPaging,
  DxColumnChooser,
  DxPosition,
  DxColumnChooserSearch,
  DxSearchPanel,
  DxPager,
  DxButton,
  DxTexts,
  DxScrolling,
  DxSorting,
  DxToolbar,
  DxItem,
  DxRequiredRule,
  DxEmailRule,
  DxStringLengthRule,
  DxKeyboardNavigation,
  DxSummary,
  DxTotalItem,
  DxMasterDetail,
} from "devextreme-vue/data-grid";

import MasterDetailGrid from "@/components/devexpress/grid/remote/MasterDetail.vue";

// import DxTextBox from "devextreme-vue/text-box";
// import DxDateBox from "devextreme-vue/date-box";
import DxTextArea from "devextreme-vue/text-area";
// import DxCheckBox from "devextreme-vue/check-box";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Inputmask from "inputmask";
import { showModal, removeTooltips } from "@/core/helpers/dom";
export default {
  name: "DevExpressRemoteGrid",
  components: {
    PageFilter,
    InstantFilterModal,
    BulkUpdateModal,
    InlineErrorModal,
    CellEditModal,
    ShortcutInformationModal,

    //Devexpress Data Grid
    DxDataGrid,
    DxMasterDetail,
    MasterDetailGrid,
    DxColumn,
    DxScrolling,
    DxEditing,
    DxSorting,
    DxPaging,
    DxButton,
    DxButtonToolbar,
    DxColumnChooser,
    DxPosition,
    DxColumnChooserSearch,
    DxSearchPanel,
    DxKeyboardNavigation,
    DxPager,
    DxTexts,
    DxToolbar,
    DxItem,
    DxRequiredRule,
    DxEmailRule,
    DxStringLengthRule,
    DxSummary,
    DxTotalItem,

    //Devexpress Components
    // DxTextBox,
    // DxDateBox,
    DxTextArea,
    // DxCheckBox,

    //Custom Devexpress Edit Cell Component
    PagedSelectList,
    SelectList,
    RadioSelectList,
    FileInput,
    FileInputWithEdit,
    PhoneInput,
    NumericTextBox,
    TextBox,
    CheckBox,
    DatePicker,
    Spatial,
  },
  props: {
    gridId: {
      type: String,
      default: String.newGuid(),
    },
    isLookupRelationTable: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object,
      default() {
        return null;
      },
    },
    table: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ["onRequestUnSuccessful", "onRequestFinally"],
  data() {
    return {
      numberFormatInfo: this.$root.getNumberFormat(),
      dateFormatInfo: this.$root.getDateTimeFormat("short"),
      dateTimeFormatInfo: this.$root.getDateTimeFormat(),

      primaryKey: "PUBLICID",
      serialNoKey: "SX_DISPLAY_ORDER",
      dxSystemPrimaryKey: "__KEY__",
      dxRowIsEditing: false,
      isDxRowInlineEditing: false,
      isDxGridCrudAction: false,
      rePaintChangesOnly: false,
      gridRefName: "grid",
      dataSource: null,
      isNotDrawAsMobile: false,

      gridDataIsLoading: true,

      selectionMode: { mode: "single" }, //or multiple
      pagerDisplayMode: "full", //or compact
      columnResizingMode: this.$isMobile() ? "nextColumn" : "widget", //or nextColumn
      editingMode: this.$isMobile() ? "form" : "row", //or batch, cell, popup
      defaultPageSizes: [10, 25, 50, 100, 200, 500],
      pageSizes: [],
      pageSize: 50,

      isDoubleScrollNeeded: false,
      currentFontSize: 16,
      minFontSize: 14,
      maxFontSize: 20,
      maxSortOrder: 3,

      deleteRecordId: null,

      gridShow: false,
      isTableLoaded: false,
      spinShow: false,
      isLoaded: false,
      isTableShow: false,
      tableNoPermitted: false,
      onCellDoubleClicked: false,
      editSettings: {
        allowAdding: true,
        allowEditing: true,
      },

      //buttons
      filterButtons: [],
      customActionButtons: [],
      devExpressActionButtons: [],
      clickedCurrentButton: [],

      columns: [],
      hiddenDependenyFieldItems: [],
      hiddenControllingFieldItems: [],
      headerCellTemplates: [],
      editCellTemplates: [],
      masterDetailTemplates: [],
      pageData: null,
      pagedItems: [],
      pageAllFields: [],
      aggregates: [],

      newRowFieldDefaultValues: null,

      editingField: null,

      shortDateTimeFormat: this.$root.getDateTimeFormat("short"),
      longDateTimeFormat: this.$root.getDateTimeFormat(),

      //inline errors
      inlineInformations: [],
      inlineErrors: [],
      inlineWarnings: [],
      businessRuleErrors: [],
      businessRuleResponse: {},
      isForcingSaveButtonClicked: false,

      //request errors
      responseError: [],

      //cell edit
      selectedRow: null,
      selectedRowData: null,
      selectedRecordCellField: null,
      selectedRowIndex: 0,
      selectedCellIndex: 0,

      bulkActionTypes: [
        {
          key: 1,
          value: this.$t(
            "Components.DropDown.BulkUpdate",
            {},
            { locale: this.$store.state.activeLang }
          ),
          attributes: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#modalBulkUpdate_" + this.gridId,
          },
          iconClass: "fas fa-edit",
        },
      ],

      radioSelectListValues: [],

      noDataText: this.$t(
        "BaseModelFields.NoRecords",
        {},
        { locale: this.$store.state.activeLang }
      ),
    };
  },
  computed: {
    grid() {
      return this.$refs[this.gridRefName]
        ? this.$refs[this.gridRefName].instance
        : null;
    },
  },
  created() {
    if (!this.isLookupRelationTable) {
      this.pageSizes = this.defaultPageSizes;
      this.$root.getSetScripts(this.$route.params.key, "List").then(() => {
        this.defaultProperties();
        this.getData();
      });
    } else {
      this.pageSize = this.table.pageItemCount;
      this.pageSizes = [this.pageSize, ...this.defaultPageSizes]
        .sort(function (a, b) {
          return a - b;
        })
        .filter(function (x, i, a) {
          return a.indexOf(x) == i;
        });
    }
  },
  mounted() {
    //inline download
    var self = this;
    $("body")
      .off("click", ".download-file")
      .on("click", ".download-file", function () {
        self.$root.downloadFile({
          id: $(this).attr("id"),
          name: $(this).attr("name"),
        });
      });

    $("body")
      .off("click", ".view-map-with-swal")
      .on("click", ".view-map-with-swal", function () {
        var el = $(this),
          title = el.data("title"),
          latLng = el.data("latlng");

        if (
          String.isNullOrWhiteSpace(title) ||
          String.isNullOrWhiteSpace(latLng)
        )
          return;

        var lat = latLng.split(",")[0],
          lng = latLng.split(",")[1];

        self.$root.viewMapWithSwal(title, lat, lng);
      });

    if (!this.isLookupRelationTable && !this.$isMobile()) {
      $(window)
        .off("scroll")
        .on("scroll", function () {
          self.onWindowScrolling();
        });
    }
  },
  watch: {
    "$store.state.gridEditSettings"(setting) {
      if (!String.isNullOrWhiteSpace(setting)) {
        this.editSettings.allowAdding = setting.allowAdding;
        this.editSettings.allowEditing = setting.allowEditing;
      }

      if (this.dxRowIsEditing) {
        this.cancelRow();
      }
    },
  },
  methods: {
    masterDetailGridCellDblClick(field, args, record) {
      this.selectedRecordCellField = field;
      this.selectedRow = args;
      this.selectedRowData = record;
      this.selectedRowIndex = args.rowIndex;
      this.selectedCellIndex = args.columnIndex;
      showModal(document.getElementById(this.$refs.cellEditModal.modalId));
    },
    pageTitleAndBreadcrumbConfigurationForList() {
      var customObjectName = this.pageData.customObject.name,
        customObjectPluralName = this.pageData.customObject.pluralName;
      if (this.$store.state.isMultiLanguage) {
        var localizationValue =
          this.$store.getters.getLocalizationValuesByParameters({
            itemFormulaName: this.pageData.customObject.formulaName,
            itemTypeId: 1,
          });
        if (localizationValue) {
          customObjectName = localizationValue.value;
        }

        var localizationValueForPluralName =
          this.$store.getters.getLocalizationValuesByParameters({
            itemFormulaName: this.pageData.customObject.formulaName,
            itemTypeId: 11,
          });
        if (localizationValueForPluralName) {
          customObjectPluralName = localizationValueForPluralName.value;
        }
      }

      var title = this.$t(
          "Set.ListFormat",
          {},
          { locale: this.$store.state.activeLang }
        ).replaceAll("OBJECT_NAME", customObjectName),
        routeObject = this.$route.query.isAdhoc
          ? {
              name: customObjectPluralName,
              isRouteLink: true,
              html: `/set/list/${this.$route.params.key}?filterId=${this.pageData.filter.publicId}`,
            }
          : {
              name: customObjectPluralName,
            };

      this.$root.changeDocumentTitle(title);
      setCurrentPageBreadcrumbs(title, [{ name: title, ...routeObject }]);
    },
    inlineEditToggleOff() {
      this.editSettings = {
        allowAdding: true,
        allowEditing: true,
      };
    },
    inlineEditToggleOn() {
      this.editSettings = {
        allowAdding: false,
        allowEditing: false,
      };
    },
    bulkActionSelection(selectedItem) {
      // this.$refs.bulkUpdateModal.getFields();
    },
    filterHeaderField($event, field) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();

      showModal(document.getElementById("modalInstantFilter"));

      this.$root.select2SetValue(
        $("#instantFilterField"),
        false,
        field.publicId,
        field.name
      );

      this.$refs.instantFilterModal.fieldId = field.publicId;
      this.$refs.instantFilterModal.field = {
        id: field.publicId,
        text: field.name,
      };
      this.$refs.instantFilterModal.fieldType = null;
      this.$refs.instantFilterModal.getOperators();
    },
    onKeyDown(e) {
      var event = e.event,
        isCtrlK = event.ctrlKey === true && event.keyCode === 75,
        isCtrlY = event.ctrlKey === true && event.keyCode === 89;
      if (isCtrlK || isCtrlY) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        if (isCtrlK) {
          this.saveRow();
        } else if (isCtrlY) {
          if (this.$route.meta.isListPage) {
            if (this.editSettings.allowAdding) {
              $(".dx-grid-add-row").trigger("click");
            } else {
              this.$router.push({
                name: "new",
                params: { key: this.$route.params.key },
              });
            }
          } else {
            this.addRow();
          }
        }
      }
    },
    onRowValidating(e) {
      // if (!this.$isMobile()) return;

      var obj = { ...e.newData },
        messages = [],
        self = this,
        grid = $(`#${this.gridId}`);

      if (String.isNullOrWhiteSpace(obj) || !grid) return;

      //only update record
      if (e.key && e.oldData) {
        Object.keys(e.oldData).forEach((key) => {
          if (
            typeof e.newData[key] === "undefined" &&
            !key.includes(self.$fieldPublicIdFormulaExtension) &&
            key !== self.primaryKey &&
            key !== "SERIALNO"
          ) {
            var publicIdFormula = `${key}${self.$fieldPublicIdFormulaExtension}`,
              publicIdValue = e.oldData[publicIdFormula],
              isPublicIdValue = !String.isNullOrWhiteSpace(publicIdValue);

            obj[key] = isPublicIdValue ? publicIdValue : e.oldData[key];
          }
        });
      }

      Object.keys(obj).forEach(function (key) {
        var field = self.pageAllFields.find((f) => f.formulaName == key),
          filterField = self.columns.find((f) => f.formulaName == key),
          value = String.isNullOrWhiteSpace(obj[key])
            ? null
            : obj[key].toString();

        if (!field) return;

        if (!filterField) {
          var hiddenDependency = self.hiddenDependenyFieldItems.find(
              (f) => f.field.formulaName == key
            ),
            hiddenControlling = self.hiddenControllingFieldItems.find(
              (f) => f.field.formulaName == key
            );

          if (!hiddenControlling && !hiddenDependency) {
            return;
          }

          if (hiddenControlling) {
            filterField = hiddenControlling.filterField;
          }

          if (hiddenDependency) {
            filterField = hiddenDependency.filterField;
          }
        }

        var columnIndex = self.grid.getVisibleColumnIndex(field.formulaName),
          messageIndex = messages.length + 1,
          fieldNameHtmlFormat = `<b>${field.name}</b>`,
          emailNotValidMessage = self
            .$t(
              "FieldWarnings.EmailNotValidFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          phoneNotValidMessage = self
            .$t(
              "FieldWarnings.PhoneNotValidFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          fieldLengthNotValidMessage = self
            .$t(
              "FieldWarnings.FieldLengthNotValidFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          fieldIsRequiredMessage = self
            .$t(
              "FieldWarnings.FieldIsRequiredFormat",
              {},
              { locale: self.$store.state.activeLang }
            )
            .replaceAll("FIELD_NAME", fieldNameHtmlFormat),
          columnOrderNo = `<span class='badge badge-validation bg-info fs-6'>
            ${columnIndex}${self.$t(
            "BaseModelFields.NdPlace",
            {},
            { locale: self.$store.state.activeLang }
          )}</span>`;

        if (
          (filterField.required || field.isRequired) &&
          field.fieldType != "AutoId" &&
          !field.fieldType.includes("Formula") &&
          String.isNullOrWhiteSpace(value) &&
          field.isActive
        ) {
          messages.push(
            `${messageIndex}. ${fieldIsRequiredMessage}. ${columnOrderNo}`
          );
        }

        if (!String.isNullOrWhiteSpace(value) && field.fieldType === "Phone") {
          var intlInstance = self.$root.getPhoneInputInstance(
            self.getCellTemplateId(2, field)
          );
          if (intlInstance) {
            if (!intlInstance.isValidNumber()) {
              messages.push(
                `${messageIndex}. ${phoneNotValidMessage}. ${columnOrderNo}`
              );
            }
          }
        }

        if (
          field.fieldType == "Email" &&
          !String.isNullOrWhiteSpace(value) &&
          !String.isEmail(value)
        ) {
          messages.push(
            `${messageIndex}. ${emailNotValidMessage}. ${columnOrderNo}`
          );
        }

        if (
          field.fieldType == "Text" &&
          field.length > 0 &&
          !String.isNullOrWhiteSpace(value) &&
          value.length > field.length
        ) {
          messages.push(
            `${messageIndex}. ${fieldLengthNotValidMessage}. ${columnOrderNo}`
          );
        }
      });

      e.isValid = messages.length === 0;
      e.errorText = messages.length > 0 ? " " : null;

      setTimeout(function () {
        const errorRow = grid.find(".dx-error-message");
        if (!e.isValid) {
          errorRow.html(messages.join("<br/>"));
        } else {
          errorRow.addClass("hidden");
        }
      });
    },
    serialNoClicked() {
      if (!this.isLookupRelationTable) {
        this.gridShow = false;
      }
    },
    rowInserting(e) {
      this.$root.createEvent("SXGRID_ON_ROW_INSERTING", {
        title: "Grid On Row Inserting",
        message: "Event triggered when grid row inserting",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
          rowData: e.data,
        },
      });
    },
    setRadioSelectListValuesDefaultValues() {
      //default value
      this.radioSelectListValues = this.radioSelectListValues.map(function (f) {
        return {
          fieldPublicId: f.fieldPublicId,
          items: f.items,
        };
      });
    },
    getVisibleRequiredFields() {
      return this.grid
        .getVisibleColumns()
        .filter(
          (f) =>
            f.validationRules &&
            f.validationRules.filter((vr) => vr.type == "required").length > 0
        );
    },
    requiredFieldsControlForUpdateRecord(recordPublicId) {
      var visibleRequiredFields = this.getVisibleRequiredFields(),
        responseModel = {
          isOk: true,
          validations: [],
          message: "",
        },
        functionLocalization = {
          please: this.$t(
            "BaseModelFields.Please",
            {},
            { locale: this.$store.state.activeLang }
          ),
          ndPlace: this.$t(
            "BaseModelFields.NdPlace",
            {},
            { locale: this.$store.state.activeLang }
          ),
          checkFields: this.$t(
            "FieldWarnings.CheckFieldsShortFormat",
            {},
            { locale: this.$store.state.activeLang }
          ),
        };

      if (
        !visibleRequiredFields ||
        visibleRequiredFields.length == 0 ||
        String.isNullOrWhiteSpace(recordPublicId)
      )
        return responseModel;

      var record = this.getDataSourceItem(recordPublicId);
      if (!record) return responseModel;

      visibleRequiredFields.forEach((requiredField) => {
        var requiredFieldValue = record[requiredField.dataField];
        if (String.isNullOrWhiteSpace(requiredFieldValue)) {
          var validation = {
            formulaName: requiredField.dataField,
            caption: requiredField.caption,
            columnIndex: requiredField.visibleIndex - 1,
          };

          validation.message = `${validation.caption}(${validation.columnIndex}${functionLocalization.ndPlace})`;

          responseModel.validations.push(validation);
        }
      });

      responseModel.isOk = responseModel.validations.length == 0;
      responseModel.message = `${
        functionLocalization.please
      } ${responseModel.validations.map((m) => m.message).join(", ")} ${
        functionLocalization.checkFields
      }`;
      return responseModel;
    },
    onSaving(e) {
      // this.dxRowIsEditing =  false;

      var noChanges = e.changes.length == 0;
      if (noChanges) {
        var grid = $(e.component.$element()),
          editedRow = grid.find(".dx-edit-row:first"),
          rowIndex = editedRow.index(),
          publicId = editedRow.data("id");
        if (String.isNullOrWhiteSpace(publicId)) return;

        var requiredFieldControl =
          this.requiredFieldsControlForUpdateRecord(publicId);
        if (!requiredFieldControl.isOk) {
          e.cancel = true;
          e.promise = Promise.reject(requiredFieldControl.message);
          // setTimeout(() => {
          //   this.$root.elementScrollToTop(
          //     $("html, body"),
          //     grid.find(".dx-error-row").offset().top - 150
          //   );
          // });
          return;
        }

        var model = {
          customObjectPublicId: this.isLookupRelationTable
            ? this.table.lookupObjectPublicId
            : this.pageData.customObject.publicId,
          customObjectKey: this.isLookupRelationTable
            ? this.table.lookupObjectUrlName
            : this.$route.params.key,
          recordPublicId: publicId,
          values: [],
        };

        //TODO: #2426 fix
        var onlyLiveLookupValues = [];
        var dataItems = this.grid.getDataSource().items();
        if (dataItems.length > 0) {
          var recordItem = dataItems.find(
            (f) => f[this.primaryKey] === publicId
          );
          if (recordItem) {
            onlyLiveLookupValues = this.getRowLiveLookupValues(recordItem);
          }
        }

        if (onlyLiveLookupValues.length > 0) {
          onlyLiveLookupValues.forEach((liveLookupValueObj) => {
            if (!model.values.find((f) => f.key === liveLookupValueObj.key)) {
              model.values.push({
                key: liveLookupValueObj.key,
                value: liveLookupValueObj.value,
              });
            }
          });
        }

        if (this.isForcingSaveButtonClicked) {
          model.isForcingSave = true;
        }

        this.inlineInformations = [];
        this.inlineErrors = [];
        this.inlineWarnings = [];
        this.businessRuleErrors = [];

        this.$root.createEvent("SXGRID_ON_ROW_UPDATED", {
          title: "Grid On Row Updated",
          message: "Event triggered when grid row updated",
          grid: {
            id: this.gridId,
            data: this.pageData,
            pagedItems: this.pagedItems,
            rowData: {},
            isNoChanges: true,
          },
        });

        this.grid.beginCustomLoading();
        this.$appAxios
          .post("rws-SetController-Edit", model)
          .then((response) => {
            this.grid.endCustomLoading();
            var result = response.data;

            this.isForcingSaveButtonClicked = false;

            this.businessRuleResponse = result.businessRuleResponse;

            if (
              this.businessRuleResponse.businessRuleResponses.length > 0 &&
              this.businessRuleResponse.notificationType !== "Tooltip"
            ) {
              this.businessRuleErrors =
                this.businessRuleResponse.businessRuleResponses;
            }

            if (!String.isNullOrWhiteSpace(result.validations)) {
              result.validations.forEach((validation) => {
                var errorMessage = validation.message;
                if (!String.isNullOrWhiteSpace(validation.fieldName)) {
                  errorMessage = `${validation.fieldName}: ${validation.message}`;
                }
                if (
                  validation.validationType &&
                  validation.validationType.value == 2
                ) {
                  this.inlineWarnings.push({
                    message: errorMessage,
                    model: model,
                    self: this,
                    component: "Grid",
                  });
                } else if (
                  validation.validationType &&
                  validation.validationType.value == 3
                ) {
                  this.inlineInformations.push(validation);
                } else {
                  this.inlineErrors.push(errorMessage);
                }
              });
            }

            this.dxRowIsEditing = false;
            this.isDxRowInlineEditing = false;

            if (result.isOk) {
              this.newRowFieldDefaultValues = null;
              this.setRadioSelectListValuesDefaultValues();

              createToast(
                this.$t(
                  "BaseModelFields.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                {
                  showIcon: true,
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );

              if (!this.isLookupRelationTable) {
                $(".btn-inline-close").trigger("click");
              }

              this.grid.refresh();
            } else {
              this.rePaintChangesOnly = false;
              setTimeout(() => {
                this.rePaintChangesOnly = true;
                this.grid.editRow(rowIndex);
              }, 1);
              if (!String.isNullOrWhiteSpace(result.msg)) {
                this.inlineErrors.push(result.msg);
              }

              if (!String.isNullOrWhiteSpace(result.message)) {
                this.inlineErrors.push(result.message);
              }
            }

            var isInlineErrorModalOpen =
              this.businessRuleErrors.length > 0 ||
              this.inlineErrors.length > 1 ||
              this.inlineWarnings.length > 0 ||
              this.inlineInformations.length > 0;
            if (isInlineErrorModalOpen) {
              showModal(
                document.getElementById(`modalInlineError_${this.gridId}`)
              );
            }

            if (!result.isOk) {
              if (isInlineErrorModalOpen) {
                return Promise.reject(
                  this.$t(
                    "BaseModelFields.ReviewRecordErrorsFromPopup",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                );
              } else if (this.inlineErrors.length == 1) {
                return Promise.reject(this.inlineErrors[0]);
              }
            }
          })
          .catch(() => {
            //
          });
      }
    },
    rowInserted(e) {
      if (this.isLookupRelationTable && !this.getCustomSaveOptionValue()) {
        this.$root.rollupTriggerForPagingTable(
          this.element.id,
          this.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
            .fieldModels
        );
      }

      //for shortcuts to work
      this.grid.instance().focus(this.grid.getCellElement(0, 1));

      //force repaint rows
      this.grid.repaint();

      //scroll hide bug fix
      this.configurationSxScroll();

      this.$root.createEvent("SXGRID_ON_ROW_INSERTED", {
        title: "Grid On Row Inserted",
        message: "Event triggered when grid row inserted",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
          rowData: e.data,
        },
      });
    },
    rowUpdated(e) {
      if (this.isLookupRelationTable && !this.getCustomSaveOptionValue()) {
        this.$root.rollupTriggerForPagingTable(
          this.element.id,
          this.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
            .fieldModels
        );
      }

      this.$root.createEvent("SXGRID_ON_ROW_UPDATED", {
        title: "Grid On Row Updated",
        message: "Event triggered when grid row updated",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
          rowData: e.data,
          isNoChanges: false,
        },
      });
    },
    saveRow() {
      var dxCalculateFieldLoading = $(this.grid.$element()).find(
        ".dx-save-loading"
      );
      if (dxCalculateFieldLoading.length > 0) return;
      this.grid.saveEditData();
    },
    cancelRow() {
      if (this.$route.meta.isListPage) {
        $(".btn-inline-close").trigger("click");
      }
      //triggering onEditCancel method
      this.grid.cancelEditData();
    },
    onEditCanceled(e) {
      if (this.$route.meta.isListPage) {
        $(".btn-inline-close").trigger("click");
      }
      this.dxRowIsEditing = false;
      this.isDxRowInlineEditing = false;
      this.onCellDoubleClicked = false;

      this.setRadioSelectListValuesDefaultValues();

      this.$root.createEvent("SXGRID_ON_EDIT_CANCELED", {
        title: "Grid On Edit Canceled",
        message: "Event triggered when grid edit cancel",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
          rowData: e.changes,
        },
      });
    },
    onEditingStart(e) {
      // this.rePaintChangesOnly = true;
      this.dxRowIsEditing = true;
      this.isDxRowInlineEditing = true;
      if (!this.onCellDoubleClicked) {
        this.sxScrollSetLeftValue(0);
      }

      this.$root.createEvent("SXGRID_ON_EDITING_START", {
        title: "Grid On Editing Start",
        message: "Event triggered when grid editing start",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
          rowData: e.data,
        },
      });
    },
    initNewRow(e) {
      this.$root.createEvent("SXGRID_INIT_NEW_ROW", {
        title: "Grid Init New Row",
        message: "Event triggered when grid init new row",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
        },
      });
      this.dxRowIsEditing = true;
      this.isDxRowInlineEditing = false;
      this.fieldDefaultValueItemsUnLocked();
      this.sxScrollSetLeftValue(0);
      this.setRadioSelectListValuesDefaultValues();
    },
    editCellFieldIsDisabled(field, rowData) {
      if (!this.dxRowIsEditing) return;

      var filterField = this.columns.find(
        (f) => f.formulaName == field.formulaName
      );
      if (!filterField) return;

      return (
        field.disabled ||
        filterField.disabled ||
        !field.isActive ||
        field.fieldType.includes("AutoId") ||
        field.fieldType.includes("Rollup") ||
        field.fieldType.includes("Formula") ||
        (rowData[this.primaryKey] && !field.isEditable)
      );
    },
    editCellTemplateItemValueChangedForDxComponents(
      e,
      template,
      isCalculatedField = false
    ) {
      this.editingField = template.field;

      var element = $(e.element),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      if (isCalculatedField) {
        this.$root.calculateTriggerFieldForGrid(this, template);
      }

      this.grid.cellValue(rowIndex, columnIndex, e.value);
    },
    editCellTemplateValueChangedForPhoneInput(template, value) {
      this.editingField = template.field;

      var fullNumberInput = $(`[name="${template.id}_full_phone_number"]`);
      if (fullNumberInput) {
        value = fullNumberInput.val();
      }

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForSpatial(template, value) {
      this.editingField = template.field;

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForSelect2Components(
      field,
      elementId,
      text,
      value,
      template
    ) {
      this.editingField = template.field;

      var newRow = this.grid.getVisibleRows().find((f) => f.isNewRow);
      if (newRow) {
        var newRowData = newRow.data;
        newRowData[`${template.field.formulaName}_TEXT`] = text;
      }

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      var row = element.closest("tr"),
        childs = this.$root.getChildFieldPublicIds(field.publicId);

      childs.forEach((childFieldPublicId) => {
        var gridItem = row.find(`[data-publicid='${childFieldPublicId}']`);
        if (gridItem) {
          var childColumnIndex = this.grid.getVisibleColumnIndex(
            gridItem.data("formulaname")
          );
          this.grid.cellValue(rowIndex, childColumnIndex, null);
        }
      });

      this.grid.cellValue(rowIndex, columnIndex, value);

      if (!String.isNullOrWhiteSpace(field.controllingPublicId)) {
        this.$root.select2PagedSelectlistDependencyChildTriggerForGrid(
          $(`#${elementId}`).closest("tr"),
          field,
          template
        );
      }

      if (
        !String.isNullOrWhiteSpace(value) &&
        !String.isNullOrWhiteSpace(text)
      ) {
        this.$root.select2SetValue(
          $(`#${elementId}`),
          field,
          value,
          text,
          true
        );
      }
    },
    editCellTemplateValueChangedForDatePickerComponents(
      template,
      isCalculatedField = false,
      datePickerType = "datetime",
      value = null
    ) {
      this.editingField = template.field;
      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      if (
        !element ||
        !this.$root.validateDatePickerType(template.field.fieldType)
      )
        return;

      if (isCalculatedField) {
        this.$root.calculateTriggerFieldForGrid(this, template);
      }

      if (!String.isNullOrWhiteSpace(value)) {
        if (datePickerType == "datetime") {
          value = this.$moment(value, "yyyy-MM-DDTHH:mm:ss").format(
            this.dateTimeFormatInfo.calendar
          );
        } else if (datePickerType == "date") {
          value = this.$moment(value, "yyyy-MM-DD").format(
            this.dateFormatInfo.calendar
          );
        } else if (datePickerType == "time") {
          value = this.$moment(value, "yyyy-MM-DDTHH:mm").format(
            this.dateFormatInfo.time
          );
        }
      }

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForFileInput(template, value) {
      this.editingField = template.field;

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForTextBoxFieldTypes(template, value) {
      this.editingField = template.field;

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForRadioSelectListFieldTypes(template, value) {
      this.editingField = template.field;

      var element = $(`[name="${template.id}"]`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.radioSelectListValues.find(
        (f) => f.fieldPublicId == template.field.publicId
      ).editCellFieldValueChanges = true;

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForCheckBoxFieldTypes(template, value) {
      this.editingField = template.field;

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    editCellTemplateValueChangedForNumberFieldTypes(template, value) {
      this.editingField = template.field;

      var element = $(`#${template.id}`),
        rowIndex = element.closest("tr").index(),
        columnIndex = this.grid.getVisibleColumnIndex(
          template.field.formulaName
        );

      this.grid.cellValue(rowIndex, columnIndex, value);
    },
    getDataSourceItem(publicId) {
      var items = this.grid.getDataSource().items();
      return items && items.find((f) => f[this.primaryKey] == publicId);
    },
    tableToggleDisplay() {
      this.$parent.isTableShow = false;
    },
    fieldDefaultValueItemsUnLocked() {
      if (
        this.newRowFieldDefaultValues &&
        this.newRowFieldDefaultValues.items
      ) {
        this.newRowFieldDefaultValues.items.forEach((fieldDefaultValue) => {
          fieldDefaultValue.locked = false;
        });
      }
    },
    editCellFieldValue(field, rowData = [], returnType = "text") {
      if (!this.dxRowIsEditing) return;

      var value = rowData[field.formulaName],
        recordPublicId = rowData[this.primaryKey],
        isNewRecord = String.isNullOrWhiteSpace(recordPublicId),
        isEditRecord = !isNewRecord;

      if (
        isNewRecord &&
        returnType == "text" &&
        this.$root.fieldTypes.select.includes(field.fieldType)
      ) {
        value = rowData[field.formulaName + "_TEXT"];
      }

      if (isNewRecord && String.isNullOrWhiteSpace(value)) {
        var fieldDefaultValues = this.newRowFieldDefaultValues.items,
          fieldDefaultValue = !String.isNullOrWhiteSpace(fieldDefaultValues)
            ? fieldDefaultValues.find((f) => f.key == field.formulaName)
            : null,
          defaultValueIsNull = String.isNullOrWhiteSpace(fieldDefaultValue);

        if (defaultValueIsNull) {
          value = null;
        } else if (fieldDefaultValue.value.includes("error")) {
          value = null;

          var valueIsLocked =
            "locked" in fieldDefaultValue && fieldDefaultValue.locked;
          if (!valueIsLocked) {
            fieldDefaultValue.locked = true;
          }
        } else {
          valueIsLocked =
            "locked" in fieldDefaultValue && fieldDefaultValue.locked;
          if (!valueIsLocked) {
            if (
              (this.$root.fieldTypes.select.includes(field.fieldType) &&
                returnType == "id") ||
              (!this.$root.fieldTypes.select.includes(field.fieldType) &&
                returnType == "text")
            ) {
              fieldDefaultValue.locked = true;
            }

            value = fieldDefaultValue.value;
          }

          if (
            !String.isNullOrWhiteSpace(value) &&
            this.$root.fieldTypes.select.includes(field.fieldType)
          ) {
            // var fieldValueIsMultiple =
            //     value.split(this.$systemSeparator).length > 2,
            var splittedValue = value.split(this.$selectSeparator);
            value = splittedValue[returnType == "id" ? 0 : 1];
            // if (fieldValueIsMultiple) {
            //   value = splittedValue[returnType == "id" ? 0 : 1].split(
            //     this.$systemSeparator
            //   )[0];
            // } else {
            //   value = splittedValue[returnType == "id" ? 0 : 1];
            // }
          }
        }

        //emiting default value to column
        if (
          returnType == "text" &&
          this.$root.fieldTypes.select.includes(field.fieldType)
        ) {
          rowData[field.formulaName + "_TEXT"] = value;
        } else {
          rowData[field.formulaName] = value;
        }
      } else if (isEditRecord && !String.isNullOrWhiteSpace(value)) {
        if (this.$root.fieldTypes.select.includes(field.fieldType)) {
          if (returnType == "id") {
            value =
              rowData[
                `${field.formulaName}${this.$fieldPublicIdFormulaExtension}`
              ];
          }
        }
      }

      if (this.$root.fieldTypes.checkbox.includes(field.fieldType)) {
        if (!String.isNullOrWhiteSpace(value)) {
          value = Boolean.toBool(value.toString());
        } else {
          value = false;
        }
      }

      return value;
    },
    dateFormating(type, dataItem) {
      if (
        String.isNullOrWhiteSpace(dataItem) ||
        String.isNullOrWhiteSpace(type) ||
        dataItem.includes("-")
      )
        return String.isNullOrWhiteSpace(dataItem) ? null : dataItem;

      if (type == "datetime") {
        return this.$moment(dataItem, this.dateTimeFormatInfo.calendar).format(
          "yyyy-MM-DDTHH:mm:ss"
        );
      } else if (type == "date") {
        return this.$moment(dataItem, this.dateFormatInfo.calendar).format(
          "yyyy-MM-DD"
        );
      } else if (type == "time") {
        var valueFormat = this.$root.getTimeFormatByValue(
          dataItem,
          this.dateTimeFormatInfo
        );

        return this.$moment(dataItem, valueFormat).format(
          "yyyy-MM-DDTHH:mm:ss"
        );
      }

      return dataItem;
    },
    addRow(e) {
      var self = this;
      if (!this.editSettings.allowAdding) {
        localStorage.setItem(
          "LookupInfo",
          JSON.stringify({
            coId: this.table.customObjectPublicId,
            rId: this.$route.params.id,
            val: this.$t(
              "BaseModelFields.FieldIsCalcForSystem",
              {},
              { locale: this.$store.state.activeLang }
            ),
            fId: "",
          })
        );

        localStorage.setItem(
          "NewLookupTabData",
          JSON.stringify({
            id: this.table.fieldPublicId,
            fieldIds: this.table.fieldPublicId,
            data: null,
            fData: {},
            isMultiple: false,
          })
        );

        localStorage.setItem(
          "TriggeredData",
          JSON.stringify([
            { key: this.table.fieldFormulaName, value: this.$route.params.id },
          ])
        );

        this.redirectSetPage("new");

        localStorage.setItem(
          "isSaveAndContinueButtonEnabledOnWindowOpener",
          this.table.isSaveAndContinueButtonEnabledOnWindowOpener
        );

        var timer = setInterval(function () {
          var newLookupTabData = localStorage.getItem("NewLookupTabData"),
            newLookupTabDataObj = JSON.parse(newLookupTabData),
            userUsedSaveAndContinue = localStorage.getItem(
              "UserUsedSaveAndContinue"
            );

          if (
            newLookupTabDataObj &&
            (newLookupTabDataObj.data || userUsedSaveAndContinue)
          ) {
            self.grid.refresh();

            if (self.isLookupRelationTable) {
              self.$root.rollupTriggerForPagingTable(
                self.element.id,
                self.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
                  .fieldModels
              );
            }

            clearInterval(timer);

            localStorage.removeItem("NewLookupTabData");
            localStorage.removeItem("LookupInfo");
            localStorage.removeItem("UserUsedSaveAndContinue");
            localStorage.removeItem(
              "isSaveAndContinueButtonEnabledOnWindowOpener"
            );
          }
        }, 1500);
      } else {
        this.rePaintChangesOnly = false;
        if (!this.newRowFieldDefaultValues) {
          this.getTableFieldDefaultValues();
        } else {
          setTimeout(() => {
            this.rePaintChangesOnly = true;
            this.grid.addRow();
          }, 1);
        }
      }
    },
    getTableFieldDefaultValues() {
      this.grid.beginCustomLoading(
        this.$t(
          "BaseModelFields.DefaultValuesRequestMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      );

      var model = {};
      if (this.isLookupRelationTable) {
        var dataModel = {
          publicId: this.$route.params.id,
          fieldsValues: [],
        };
        // if (this.$route.meta.isEditPage) {
        //   var parentModel = this.$root.modelCreate("#editForm"),
        //     parentModelOnlyValues = parentModel.values.filter(
        //       (f) => !f.fieldType.includes("Formula")
        //     );

        //   if (parentModelOnlyValues && parentModelOnlyValues.length > 0) {
        //     //changed items filtering and mapping
        //     var changedFieldValues = [],
        //       self = this;
        //     parentModelOnlyValues.forEach((fieldValue) => {
        //       var isChangedValue =
        //           fieldValue.value.trim() !== fieldValue.oldValue.trim(),
        //         isSelectlistItem = self.$root.fieldTypes.select.includes(
        //           fieldValue.fieldType
        //         ),
        //         textOldTextNotNull =
        //           !String.isNullOrWhiteSpace(fieldValue.text) &&
        //           !String.isNullOrWhiteSpace(fieldValue.oldText);
        //       if (
        //         fieldValue.fieldType.includes("Phone") &&
        //         fieldValue.value.trim().replaceAll(" ", "") !==
        //           fieldValue.oldValue.trim().replaceAll(" ", "")
        //       ) {
        //         changedFieldValues.push(fieldValue);
        //       } else {
        //         if (isSelectlistItem) {
        //           if (isChangedValue) {
        //             changedFieldValues.push(fieldValue);
        //           } else if (
        //             textOldTextNotNull &&
        //             fieldValue.text !== fieldValue.oldText
        //           ) {
        //             changedFieldValues.push(fieldValue);
        //           }
        //         } else if (isChangedValue) {
        //           changedFieldValues.push(fieldValue);
        //         }
        //       }
        //     });

        //     parentModelOnlyValues = changedFieldValues.map(function (m) {
        //       return {
        //         key: m.key,
        //         value: m.value,
        //       };
        //     });

        //     dataModel.fieldsValues = [
        //       ...dataModel.fieldsValues,
        //       ...parentModelOnlyValues,
        //     ];
        //     model = {
        //       viewFilterPublicId: this.pageData.filter.publicId,
        //       relationFieldFormulaName: this.table.fieldFormulaName,
        //       data: JSON.stringify(dataModel),
        //     };
        //   }
        // } else {
        //   model = {
        //     viewFilterPublicId: this.pageData.filter.publicId,
        //     relationFieldFormulaName: this.table.fieldFormulaName,
        //     data: JSON.stringify(dataModel),
        //   };
        // }
        model = {
          viewFilterPublicId: this.pageData.filter.publicId,
          relationFieldFormulaName: this.table.fieldFormulaName,
          data: JSON.stringify(dataModel),
        };
      } else {
        model = {
          viewFilterPublicId: this.pageData.filter.publicId,
        };
      }

      model.isLookupRelationRequest = this.isLookupRelationTable;
      this.$appAxios
        .post("rws-SetController-NewPageForViewFilter", model)
        .then((response) => {
          this.grid.endCustomLoading();

          var result = response.data;
          if (result.isOk) {
            this.newRowFieldDefaultValues = {
              recordPublicId: result.recordPublicId,
              items: result.values,
            };
            this.rePaintChangesOnly = true;
            this.grid.addRow();
          } else {
            createToast(result.msg, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    updatedCell(newValue, selectedRowData, selectedRecordCellField) {
      var record = this.pagedItems.find(
        (f) => f[this.primaryKey] == selectedRowData[this.primaryKey]
      );
      if (String.isNullOrWhiteSpace(record)) return;

      record[newValue.key] = newValue.text;
      if (
        record[newValue.key + this.$fieldPublicIdFormulaExtension] ||
        this.$root.fieldTypes.select.includes(
          selectedRecordCellField.fieldType
        ) ||
        this.$root.fieldTypes.file.includes(selectedRecordCellField.fieldType)
      ) {
        record[newValue.key + this.$fieldPublicIdFormulaExtension] =
          newValue.value;
      }

      if (this.isLookupRelationTable) {
        this.$root.rollupTriggerForPagingTable(
          this.element.id,
          this.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
            .fieldModels
        );
      }
    },
    fontSizeChange(e) {
      var fontSize = ++this.currentFontSize;
      var realFontSize = this.$root.gridFontSizeChanger(this, fontSize);
      Object.setCookie("SxGridFontSize", realFontSize);
    },
    refreshDataGrid() {
      this.grid.refresh();
    },
    defaultProperties() {
      this.dataSource = null;
      this.editingField = null;
      this.rePaintChangesOnly = false;
      this.dxRowIsEditing = false;
      this.isDxRowInlineEditing = false;
      this.isNotDrawAsMobile = false;

      //state cancel edit data
      if (this.grid) {
        this.grid.cancelEditData();
      }

      this.gridShow = false;
      this.$parent.gridShow = false;

      this.isDoubleScrollNeeded = false;

      this.columns = [];
      this.headerCellTemplates = [];
      this.editCellTemplates = [];
      this.masterDetailTemplates = [];
      this.aggregates = [];
      this.customActionButtons = [];
      this.devExpressActionButtons = [];

      this.pageData = null;
      this.pageAllFields = [];
      this.pagedItems = [];

      this.newRowFieldDefaultValues = null;
      this.triggerFields = [];

      this.currentFontSize = 16;
      this.onCellDoubleClicked = false;
    },
    initialized(e) {
      //ui before
      this.$root.createEvent("SXGRID_INITIALIZED", {
        title: "Grid Initialized",
        message: "Event triggered when grid initialized",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
        },
      });
    },
    setTableGradientAndFontColor() {
      var parent = $(`#${this.gridId}-parent`),
        grid = $(`#${this.gridId}`);

      if (
        !String.isNullOrWhiteSpace(this.element.linearGradient) ||
        !String.isNullOrWhiteSpace(this.element.tableFontColor) ||
        !String.isNullOrWhiteSpace(this.element.fontColor)
      ) {
        if (!String.isNullOrWhiteSpace(this.element.linearGradient)) {
          grid
            .find(
              ".dx-toolbar, .dx-datagrid-header-panel, .dx-pager, .dx-scrollable-scroll-content"
            )
            .css("background", this.element.linearGradient);
        }

        if (!String.isNullOrWhiteSpace(this.element.fontColor)) {
          grid.find(".btn-dx-grid-title").css("color", this.element.fontColor);
        }

        if (!String.isNullOrWhiteSpace(this.element.tableFontColor)) {
          grid
            .find(
              `td[role='columnheader'],
              .dx-datagrid-nodata,
              .btn-sx-cell-editing-enabled-info i`
            )
            .css("color", this.element.tableFontColor);
        }
      }
    },
    contentReady(e) {
      if (this.columns && this.columns.length > 0) {
        if (this.isLookupRelationTable) {
          this.setTableGradientAndFontColor();
        }

        var fontSizeCookieValue = Object.readCookie("SxGridFontSize");
        if (!String.isNullOrWhiteSpace(fontSizeCookieValue)) {
          this.currentFontSize = fontSizeCookieValue;
          this.$root.gridFontSizeChanger(this, fontSizeCookieValue);
        }

        if (this.isLookupRelationTable) {
          this.gridShow = true;
          this.configurationSxScroll();
        } else {
          setTimeout(() => {
            this.gridShow = true;
            this.pageTitleAndBreadcrumbConfigurationForList();
            this.configurationSxScroll();
          }, 100);
        }

        this.$root.createEvent("SXGRID_CONTENT_READY", {
          title: "Grid Content Ready",
          message: "Event triggered when grid content ready",
          grid: {
            id: this.gridId,
            data: this.pageData,
            pagedItems: this.pagedItems,
          },
        });
      }
    },
    configurationSxScroll() {
      var contentScroll = $(`#${this.gridId} .dx-datagrid-content:first`)[0],
        sxScroll = $(`#${this.gridId}-double-scroll-wrapper .double-scroll`);

      if (!contentScroll || !sxScroll) return;

      this.isDoubleScrollNeeded =
        contentScroll.scrollWidth > contentScroll.clientWidth;

      sxScroll.width(contentScroll.scrollWidth);
    },
    sxScrollHandle() {
      var dxScroll = this.grid.getScrollable(),
        customScrollLeftMargin = $(
          `#${this.gridId}-double-scroll-wrapper`
        ).scrollLeft();

      dxScroll.scrollTo({
        left: customScrollLeftMargin,
      });
    },
    onWindowScrolling() {
      var scroll = $(`#${this.gridId}-double-scroll-wrapper`),
        grid = $(`#${this.gridId}`),
        sxHeader = $("#sx_header"),
        sxToolbar = $("#sx_toolbar"),
        isSxToolbarSticky = sxToolbar.css("position") == "sticky",
        headerPanel = grid.find(".dx-datagrid-header-panel:first"),
        header = grid.find(".dx-datagrid-headers:first"),
        isWindowScrolling = $(window).scrollTop() > 0,
        topValues = {
          scroll: 0,
          header: 0,
          headerPanel: 0,
        },
        opacity = 1;

      if (isWindowScrolling) {
        opacity = 0.7;
        if (isSxToolbarSticky && sxToolbar.length > 0) {
          topValues.scroll = sxToolbar.outerHeight();
          topValues.header = sxToolbar.outerHeight();
          topValues.headerPanel = sxToolbar.outerHeight();
        }

        if (sxHeader.length > 0) {
          topValues.scroll += sxHeader.outerHeight();
          topValues.header += sxHeader.outerHeight();
          topValues.headerPanel += sxHeader.outerHeight();
        }

        if (headerPanel.length > 0) {
          topValues.scroll += headerPanel.outerHeight();
          topValues.header += headerPanel.outerHeight();
        }

        if (header.length > 0) {
          topValues.scroll += header.outerHeight();
        }
      }

      header.css({
        top: topValues.header,
      });

      headerPanel.css({
        top: topValues.headerPanel,
      });

      scroll.css({
        top: topValues.scroll,
        opacity: opacity,
      });
    },
    redirectSetPage(routeName, publicId) {
      var url = `/set/${routeName}/${
        this.isLookupRelationTable
          ? this.table.lookupObjectUrlName
          : this.$route.params.key
      }`;
      if (routeName != "new") {
        url += "/" + publicId;
      }

      this.isLookupRelationTable
        ? window.open("#" + url, "_blank")
        : this.$router.push(url);
    },
    customActionButtonClick($event, button) {
      var rowData = $event.row.data,
        recordPublicId = rowData[this.primaryKey],
        customObjectKey = this.isLookupRelationTable
          ? this.table.lookupObjectUrlName
          : this.$route.params.key,
        clickedButton = $($event.event.currentTarget).find("button");

      removeTooltips();
      switch (button.name) {
        case "DetailRecord":
          if (this.isLookupRelationTable) {
            this.redirectSetPage("detail", recordPublicId);
          } else {
            this.gridShow = false;
            this.$router.push({
              name: "detail",
              params: { key: this.$route.params.key, id: recordPublicId },
            });
          }
          break;
        case "SetXRMCustomButton":
          var currentButton = this.filterButtons.find(
            (f) => f.publicId == button.uId
          );
          if (String.isNullOrWhiteSpace(currentButton)) {
            return;
          }

          removeTooltips();
          if (clickedButton.attr("clicked")) {
            return;
          }

          clickedButton.attr("clicked", true).attr("disabled", true);
          setTimeout(() => {
            clickedButton.removeAttr("disabled clicked");
          }, 3000);

          this.clickedCurrentButton = currentButton;
          this.clickedCustomButton(customObjectKey, recordPublicId);
          break;
        case "DeleteRecord":
          this.deleteRecordId = recordPublicId;
          break;
        case "EditRecord":
          // if (this.isLookupRelationTable) {
          //   if (!this.table.viewFilter.isEditInlineOn) {
          //     this.redirectSetPage("edit", recordPublicId);
          //   } else {
          //     this.grid.editRow($event.row.dataIndex);
          //   }
          // } else {
          //   if (!this.editSettings.allowEditing) {
          //     this.redirectSetPage("edit", recordPublicId);
          //   } else {
          //     this.grid.editRow($event.row.dataIndex);
          //   }
          // }
          if (!this.editSettings.allowEditing) {
            this.redirectSetPage("edit", recordPublicId);
          } else {
            this.rePaintChangesOnly = false;
            setTimeout(() => {
              this.rePaintChangesOnly = true;
              this.grid.editRow($event.row.dataIndex);
            }, 1);
          }
          break;
        default:
          return;
      }
    },
    onRowPrepared(e) {
      if (
        e.rowType == "data" ||
        e.rowType == "detailAdaptive" ||
        e.rowType == "detail"
      ) {
        if (this.primaryKey in e.data) {
          e.rowElement.setAttribute("data-id", e.data[this.primaryKey]);
        }

        e.rowElement.classList.add("sx-record-item");
      }
    },
    getColumnAttributes(field) {
      return this.pageData.filter.isCellEditingEnabled
        ? {
            "data-celledit-publicid": field.publicId,
            "data-celledit-class": "grid-item",
            "data-celledit-fieldtype": field.fieldType,
            "data-celledit-inputtype": field.inputHtmlType,
            "data-celledit-maxlength": field.length,
            "data-celledit-islivelookup": field.isDataReadFromMainTable,
            "data-celledit-iscalculatedonlyoninsert":
              field.isCalculatedOnlyOnInsert,
            "data-celledit-decimalplaces": field.decimalPlaces,
            "data-celledit-inputmaskpattern": field.inputMaskPattern,
            "data-celledit-controllingpublicid": field.controllingPublicId,
            "data-celledit-lookupobjectid": field.lookupObjectPublicId,
            "data-celledit-lookupobjectname": field.lookupObjectName,
            "data-celledit-lookupobjecturl": field.lookupObjectKey,
            "data-celledit-lookupfieldids": field.lookupFieldPublicIds,
            "data-selecteditempublicids": field.defaultValuePublicIds,
            "data-celledit-organizationalunitname":
              field.organizationalUnitName,
            "data-celledit-organizationalunitfiltertype":
              field.organizationalUnitFilterType,
            "data-celledit-organizationalunitgrouppublicids":
              field.organizationalUnitGroupPublicIds,
            "data-celledit-organizationalunitdepth":
              field.organizationalUnitDept,
            "data-celledit-organizationalunitincludeitself":
              field.organizationalUnitIncludeItself,
            "data-celledit-ismultiplevalue": field.isMultipleValue,
            "data-celledit-isradio": field.isRadio,
            "data-celledit-isrequired": field.isRequired,
            "data-celledit-isunique": field.isUnique,
            "data-celledit-isactive": field.isActive,
            "data-celledit-isdisabled": "false",
            "data-celledit-iseditable": field.isEditable,
            "data-celledit-isclientcalculate": field.isClientCalculate,
            "data-celledit-iscalculateonclientchange":
              field.isCalculateOnClientChange,
            "data-celledit-ischeckunchanged": field.isCheckUnchanged,
            "data-celledit-isuniquecheckclientcalculate":
              field.isUniqueCheckClientCalculate,
            "data-celledit-istablerollup": field.isTableRollup,
            "data-celledit-isnoteditable": !field.isEditable,
            "data-rolluptable": field.tablePublicId,
            "data-celledit-rollupfield": field.rollupFieldPublicId,
            "data-celledit-rollupformula": field.aggregateFunction,
            "data-celledit-calculatetriggerfieldpublicids":
              field.calculateTriggerFieldPublicIds,
            "data-celledit-isthousandseparator": field.isThousandSeparator,
            "data-celledit-formulaname": field.formulaName,
            "data-celledit-formulatext": field.formulaText,
            "data-celledit-predefinedpublicid": field.predefinedPublicId,
            "data-celledit-predefineddependencypublicid":
              field.predefinedDependencyPublicId,
            "data-celledit-viewfilterpublicid": field.viewFilterPublicId,
          }
        : {
            "data-publicid": field.publicId,
            "data-formulaname": field.formulaName,
          };
    },
    getEditColumnAttributes(field, filterField, editCellTemplateId) {
      return {
        "data-class": "grid-item",
        "data-fieldtype": field.fieldType,
        "data-inputtype": field.inputHtmlType,
        "data-maxlength": field.length,
        "data-islivelookup": field.isDataReadFromMainTable,
        "data-iscalculatedonlyoninsert": field.isCalculatedOnlyOnInsert,
        "data-decimalplaces": field.decimalPlaces,
        "data-inputmaskpattern": field.inputMaskPattern,
        "data-controllingpublicid": field.controllingPublicId,
        "data-lookupobjectid": field.lookupObjectPublicId,
        "data-lookupobjectname": field.lookupObjectName,
        "data-lookupobjecturl": field.lookupObjectKey,
        "data-lookupfieldids": field.lookupFieldPublicIds,
        "data-selecteditempublicids": field.defaultValuePublicIds,
        "data-organizationalunitname": field.organizationalUnitName,
        "data-organizationalunitfiltertype": field.organizationalUnitFilterType,
        "data-organizationalunitgrouppublicids":
          field.organizationalUnitGroupPublicIds,
        "data-organizationalunitdepth": field.organizationalUnitDepth,
        "data-organizationalunitincludeitself":
          field.organizationalUnitIncludeItself,
        "data-ismultiplevalue": field.isMultipleValue,
        "data-isradio": field.isRadio,
        "data-isrequired": field.isRequired || filterField.required,
        "data-isunique": field.isUnique,
        "data-isactive": field.isActive,
        "data-isdisabled":
          field.disabled ||
          filterField.disabled ||
          field.fieldType.includes("Formula") ||
          !field.isActive,
        "data-iseditable": field.isEditable,
        "data-isclientcalculate": field.isClientCalculate,
        "data-iscalculateonclientchange": field.isCalculateOnClientChange,
        "data-ischeckunchanged": field.isCheckUnchanged,
        "data-isuniquecheckclientcalculate": field.isUniqueCheckClientCalculate,
        "data-istablerollup": field.isTableRollup,
        "data-isnoteditable": !field.isEditable,
        "data-rolluptable": field.tablePublicId,
        "data-rollupfield": field.rollupFieldPublicId,
        "data-rollupformula": field.aggregateFunction,
        "data-calculatetriggerfieldpublicids":
          field.calculateTriggerFieldPublicIds,
        "data-isthousandseparator": field.isThousandSeparator,
        "data-formulaname": field.formulaName,
        "data-formulatext": field.formulaText,
        "data-predefinedpublicid": field.predefinedPublicId,
        "data-predefineddependencypublicid": field.predefinedDependencyPublicId,
        "data-viewfilterpublicid": field.viewFilterPublicId,
        "data-publicid": field.publicId,
        "data-id": field.publicId,
        "data-editcelltemplateelementid": editCellTemplateId,
        spellcheck: this.$isTextSpellCheck,
        autocomplete: this.$isAutoComplete,
      };
    },
    getFieldTemplate(currentField, args, innerText) {
      var template = "";

      if (
        String.isNullOrWhiteSpace(innerText) &&
        currentField.fieldType != "Checkbox"
      ) {
        return this.$valueNotAvailableSeparator;
      }

      switch (currentField.fieldType) {
        case "Lookup":
          var fieldValueId =
            args.data[
              `${currentField.formulaName}${this.$fieldPublicIdFormulaExtension}`
            ];
          if (!String.isNullOrWhiteSpace(fieldValueId)) {
            if (currentField.isMultipleValue) {
              var fieldValueIds = fieldValueId.split(this.$systemSeparator),
                innerTexts = innerText.split(this.$systemSeparator);
              fieldValueIds.forEach((valueId, i) => {
                template += `<span class='badge border border-primary me-1 mb-1'><a target='_blank' href="#/set/detail/${currentField.lookupObjectKey}/${valueId}">${innerTexts[i]}</a></span>`;
              });
            } else {
              template = `<a target='_blank' href="#/set/detail/${currentField.lookupObjectKey}/${fieldValueId}">${innerText}</a>`;
            }
          }
          break;
        case "SelectList":
        case "OrganizationalUnit":
        case "Predefined":
          if (currentField.isMultipleValue) {
            var innerTextsSplited = innerText.split(this.$systemSeparator);
            innerTextsSplited.forEach((element) => {
              template += `<span class='badge text-dark border border-primary me-1 mb-1'>${element}</span>`;
            });
          }
          break;
        case "Checkbox":
          innerText = String.isNullOrWhiteSpace(innerText) ? false : innerText;
          template = Boolean.toBool(innerText.toString())
            ? "<i class='fa fa-check ms-2 text-success'></i>"
            : "<i class='fa fa-times ms-2 text-danger'></i>";
          break;
        case "Html":
          template = `<div class="dx-grid-html-field">${innerText}</div>`;
          break;
        case "Url":
          template = `<a target='_blank' href='${innerText}'><i class='fa fa-link text-primary'></i> ${innerText}</a>`;
          break;
        case "Email":
          template = `<a href='mailto:${innerText}'><i class='fa fa-at text-primary'></i> ${innerText}</a>`;
          break;
        case "Spatial":
        case "FormulaSpatial":
          template = `<i class="fas fa-map-marker-alt fa-2x text-primary cursor-pointer ignore-default-font-size view-map-with-swal" data-title="${currentField.name}" data-latlng="${innerText}"></i>`;
          break;
        case "Phone":
          template = `<a href='tel:${innerText}'><i class='fa fa-phone text-primary'></i> ${innerText}</a>`;
          break;
        case "Image":
        case "File":
          var recordIds =
            args.data[
              `${currentField.formulaName}${this.$fieldPublicIdFormulaExtension}`
            ];
          if (!String.isNullOrWhiteSpace(recordIds)) {
            var formFileListId = "ffl_" + String.newGuid();

            var childTemplate = "";
            recordIds = recordIds.split(this.$systemSeparator);
            innerText = innerText.split(this.$systemSeparator);
            recordIds.forEach((element, i) => {
              if (this.$root.isDocumentViewerFile(innerText[i])) {
                childTemplate += `<li><span><a>${innerText[i]}</a></span><span class="ms-2 cursor-pointer download-file" name="${innerText[i]}" id="${element}"><i class="fas fa-download text-primary"></i></span></li>`;
              } else {
                childTemplate += `<li><span class="ms-2 cursor-pointer download-file" name="${innerText[i]}" id="${element}"><i class="fas fa-download text-primary"></i> ${innerText[i]}</span></li>`;
              }
            });

            template = `<div class="form-file-list" id="${formFileListId}"><ul class="list-inline">${childTemplate}</ul></div>`;
          }
          break;
        case "Time":
          var valueFormat = this.$root.getTimeFormatByValue(
            innerText,
            this.dateTimeFormatInfo
          );

          template = this.$moment(innerText, valueFormat).format(
            this.dateTimeFormatInfo.time
          );
          break;
        default:
          break;
      }

      return String.isNullOrWhiteSpace(template) ? innerText : template;
    },
    onCellPrepared(args) {
      if (args.rowType === "header") {
        String.SetAttributes(args.cellElement, {
          "data-columnformulaname": args.column.dataField,
        });
      } else if (args.rowType === "data" || args.rowType === "detailAdaptive") {
        var currentField = this.pageAllFields.find(
            (f) => f.formulaName === args.column.dataField
          ),
          fieldBlock = $(args.cellElement),
          recordPublicId = args.data[this.primaryKey],
          isCommandColumn = args.column.name === "buttons",
          hideButtonList = this.pageData.hideButtonList;

        if (!String.isNullOrWhiteSpace(currentField)) {
          String.SetAttributes(
            args.cellElement,
            this.getColumnAttributes(currentField)
          );
        }

        if (args.row.isEditing) return;

        if (isCommandColumn && hideButtonList) {
          var hiddenButtonsForCurrentRecord = hideButtonList[recordPublicId];
          if (
            hiddenButtonsForCurrentRecord &&
            hiddenButtonsForCurrentRecord.length > 0
          ) {
            hiddenButtonsForCurrentRecord.forEach(
              (hiddenButtonRecordPublicId) => {
                fieldBlock
                  .find(`[data-id="${hiddenButtonRecordPublicId}"]`)
                  .remove();
              }
            );
          }
        }

        if (String.isNullOrWhiteSpace(currentField)) return;

        fieldBlock.html(
          this.getFieldTemplate(
            currentField,
            args,
            args.data[currentField.formulaName]
          )
        );
      }
    },
    deletedRow() {
      this.isDxGridCrudAction = true;
      this.grid.refresh();
      this.deleteRecordId = null;

      if (this.isLookupRelationTable) {
        this.$root.rollupTriggerForPagingTable(
          this.element.id,
          this.$parent.$parent.pageData.fields.find((f) => f.isMainObject)
            .fieldModels
        );
      }
    },
    getColumnType(fieldType) {
      if (fieldType.includes("Number")) {
        // return "number";
        return "string";
      } else if (fieldType.includes("Date")) {
        // return "datetime";
        return "string";
      } else if (fieldType.includes("Checkbox")) {
        return "string";
      } else {
        return "string";
      }
    },
    getCellTemplateId(typeId, field) {
      var templateType = this.$root.dxGridTemplateTypes.find(
        (f) => f.id === typeId
      );
      if (!this.$root.fieldTypes.all.includes(field.fieldType) || !templateType)
        return null;

      return `${this.gridId}-${templateType.text}${field.fieldType}Template-${field.publicId}`;
    },
    getColumns(response) {
      if (!this.pageData || !this.pageData.filter) return;

      this.tableSortOptions = this.$root.gridSortOption(
        "",
        this.pageData.filter
      );

      var self = this,
        sortIndex = 0,
        recordNotFound =
          !this.pagedItems || (this.pagedItems && this.pagedItems.length === 0),
        sortDirection = "asc",
        columns = [
          {
            uId: String.newGuid(),
            text: this.$t(
              "BaseModelFields.SerialNo",
              {},
              { locale: this.$store.state.activeLang }
            ),
            formulaName: this.serialNoKey,
            fieldModel: null,
            type: "string",
            visible: !this.$isMobile(),
            disabled: true,
            width: recordNotFound ? 25 : 0,
            format: "",
            textAlign: "center",
            template: "serialNoTemplate",
            allowEditing: false,
            allowSorting: false,
            editCellTemplate: null,
            headerCellTemplate: null,
          },
          {
            uId: String.newGuid(),
            text: this.$t(
              "BaseModelFields.RecordPublicId",
              {},
              { locale: this.$store.state.activeLang }
            ),
            formulaName: this.primaryKey,
            fieldModel: null,
            type: "string",
            visible: false,
            disabled: true,
            width: 0,
            template: null,
            format: "",
            textAlign: "",
            allowEditing: false,
            allowSorting: false,
            editCellTemplate: null,
            headerCellTemplate: null,
          },
        ];

      if (
        this.pageData &&
        this.pageData.filter &&
        this.pageData.filter.isRelationDetail
      )
        this.masterDetailTemplates.push({
          self: this,
          id: "masterDetailTemplate",
        });

      var isVisibleColumnReCalculateWidths =
          !this.$isMobile() && this.pageData.filter.clientWidth <= 0,
        changedVisibleColumnWidth = 0;
      if (isVisibleColumnReCalculateWidths) {
        changedVisibleColumnWidth = this.$root.calculateVisibleColumnWidth(
          this,
          this.isLookupRelationTable,
          this.pageData.filter
        );
      }

      this.pageData.filter.visibleFields.map(function (filterField) {
        var field = filterField.fieldModel;
        field.disabled = filterField.disabled;

        if (self.$store.state.isMultiLanguage) {
          var localizationValue =
            self.$store.getters.getLocalizationValuesByParameters({
              parentId: field.customObjectPublicId,
              itemFormulaName: field.formulaName,
              itemTypeId: 2,
            });
          if (localizationValue) {
            field.name = localizationValue.value;
          }
        }

        var sortingElement = self.tableSortOptions.columns.find(
          (f) => f.field == field.formulaName
        );
        if (sortingElement) {
          sortIndex = sortIndex + 1;
          sortDirection = sortingElement.direction.includes("Ascending")
            ? "asc"
            : "desc";
        }

        var hiddenDependencies = self.pageData.filter.hiddenFields.filter(
            (f) =>
              !String.isNullOrWhiteSpace(
                f.fieldModel.calculateTriggerFieldPublicIds
              ) &&
              f.fieldModel.calculateTriggerFieldPublicIds
                .split(self.$systemSeparator)
                .includes(field.publicId) &&
              f.fieldModel.fieldType == "Lookup"
          ),
          hiddenDependenyFieldItems = [];

        if (hiddenDependencies.length > 0) {
          hiddenDependencies.forEach((filterHiddenField) => {
            var hiddenField = filterHiddenField.fieldModel;
            var editCellTemplateId = self.getCellTemplateId(2, hiddenField),
              obj = {
                uId: String.newGuid(),
                id: editCellTemplateId + "-hidden",
                parent: self,
                field: hiddenField,
                filterField: filterHiddenField,
                cell: {
                  htmlAttributes: self.getEditColumnAttributes(
                    hiddenField,
                    filterHiddenField,
                    editCellTemplateId + "-hidden"
                  ),
                },
              };

            hiddenDependenyFieldItems.push(obj);

            self.pageAllFields.push(hiddenField);
            self.hiddenDependenyFieldItems.push(obj);

            self.pageData.filter.hiddenFields =
              self.pageData.filter.hiddenFields.filter(
                (f) => f.fieldModel.publicId != hiddenField.publicId
              );
          });
        }

        var hiddenControllingFieldPublicIds =
            self.pageData.filter.hiddenFields.filter(
              (f) =>
                !String.isNullOrWhiteSpace(field.controllingPublicId) &&
                f.fieldModel.publicId == field.controllingPublicId &&
                f.fieldModel.fieldType == "Lookup"
            ),
          hiddenControllingFieldItems = [];
        if (hiddenControllingFieldPublicIds.length > 0) {
          hiddenControllingFieldPublicIds.forEach((filterHiddenField) => {
            var hiddenField = filterHiddenField.fieldModel;
            var editCellTemplateId = self.getCellTemplateId(2, hiddenField),
              obj = {
                uId: String.newGuid(),
                id: editCellTemplateId + "-controlling-hidden",
                parent: self,
                field: hiddenField,
                filterField: filterHiddenField,
                cell: {
                  htmlAttributes: self.getEditColumnAttributes(
                    hiddenField,
                    filterHiddenField,
                    editCellTemplateId + "-controlling-hidden"
                  ),
                },
              };

            hiddenControllingFieldItems.push(obj);

            self.pageAllFields.push(hiddenField);
            self.hiddenControllingFieldItems.push(obj);

            self.pageData.filter.hiddenFields =
              self.pageData.filter.hiddenFields.filter(
                (f) => f.fieldModel.publicId != hiddenField.publicId
              );
          });
        }

        // console.log(
        //   "hiddenControllingFieldPublicIds",
        //   hiddenControllingFieldPublicIds
        // );

        var editCellTemplateId = self.getCellTemplateId(2, field),
          isEditCellTemlateIdNull =
            String.isNullOrWhiteSpace(editCellTemplateId);
        if (!isEditCellTemlateIdNull) {
          self.editCellTemplates.push({
            uId: String.newGuid(),
            id: editCellTemplateId,
            parent: self,
            child: null,
            field: field,
            filterField: filterField,
            cell: {
              htmlAttributes: self.getEditColumnAttributes(
                field,
                filterField,
                editCellTemplateId
              ),
            },
            hiddenFieldDependencies: hiddenDependenyFieldItems,
            hiddenFieldControllingDependencies: hiddenControllingFieldItems,
          });
        }

        var headerCellTemplateId = self.getCellTemplateId(0, field);
        self.headerCellTemplates.push({
          id: headerCellTemplateId,
          field: field,
          parent: self,
        });

        self.pageAllFields.push(field);
        columns.push({
          uId: String.newGuid(),
          text: field.name,
          formulaName: field.formulaName,
          fieldModel: field,
          fieldPublicId: field.publicId,
          sortOrder: sortingElement ? sortDirection : undefined,
          sortIndex: sortingElement ? sortIndex : undefined,
          visible: true,
          type: self.getColumnType(field.fieldType),
          textAlign: filterField.align,
          width: isVisibleColumnReCalculateWidths
            ? changedVisibleColumnWidth
            : filterField.width,
          required: filterField.required,
          disabled: filterField.disabled,
          allowEditing: true,
          allowSorting: true,
          headerCellTemplate: headerCellTemplateId,
          editCellTemplate: editCellTemplateId,
        });
      });

      if (this.pageData.filter.hiddenFields.length > 0) {
        this.pageData.filter.hiddenFields.map(function (filterField) {
          var field = filterField.fieldModel;
          field.disabled = filterField.disabled;

          if (self.$store.state.isMultiLanguage) {
            var localizationValue =
              self.$store.getters.getLocalizationValuesByParameters({
                parentId: field.customObjectPublicId,
                itemFormulaName: field.formulaName,
                itemTypeId: 2,
              });
            if (localizationValue) {
              field.name = localizationValue.value;
            }
          }

          var headerCellTemplateId = self.getCellTemplateId(0, field);
          self.headerCellTemplates.push({
            id: headerCellTemplateId,
            field: field,
            parent: self,
          });

          var editCellTemplateId = self.getCellTemplateId(2, field),
            isEditCellTemlateIdNull =
              String.isNullOrWhiteSpace(editCellTemplateId);
          if (!isEditCellTemlateIdNull) {
            self.editCellTemplates.push({
              uId: String.newGuid(),
              id: editCellTemplateId,
              parent: self,
              child: null,
              field: field,
              filterField: filterField,
              cell: {
                htmlAttributes: self.getEditColumnAttributes(
                  field,
                  filterField,
                  editCellTemplateId
                ),
              },
              hiddenFieldDependencies: [],
              hiddenFieldControllingDependencies: [],
            });
          }

          self.pageAllFields.push(field);
          columns.push({
            uId: String.newGuid(),
            text: field.name,
            formulaName: field.formulaName,
            fieldModel: field,
            fieldPublicId: field.publicId,
            type: self.getColumnType(field.fieldType),
            visible: false,
            textAlign: filterField.align,
            required: false,
            disabled: filterField.disabled,
            width: 0,
            allowEditing: true,
            allowSorting: true,
            editCellTemplate: editCellTemplateId,
            headerCellTemplate: headerCellTemplateId,
          });
        });
      }

      //radio select list values array prepared
      var radioSelectListFields = this.pageAllFields.filter(
        (f) => f.fieldType == "SelectList" && f.isRadio
      );
      if (radioSelectListFields.length > 0) {
        radioSelectListFields.forEach((field) => {
          this.radioSelectListValues.push({
            fieldPublicId: field.publicId,
            items: [],
          });

          this.$root.getFieldItems(field.publicId).then((response) => {
            this.radioSelectListValues.find(
              (f) => f.fieldPublicId == response.fieldPublicId
            ).items = response.items;
          });
        });
      }

      // console.log(columns);
      return columns;
    },
    isVisibleCustomActionButton(e) {
      if (e.row.isEditing) {
        return false;
      }
      return true;
    },
    getButtons(type) {
      if (!this.pageData || !this.pageData.filter) return [];
      //type => 0 : devexpress action Buttons
      //type => 1 : custom action buttons

      var buttons = [];

      buttons.push({
        uId: String.newGuid(),
        isDxActionButton: true,
        name: "save",
        label: "",
        type: "button",
        cssClass: "btn-success dx-grid-record-save btn-icon",
        iconClass: "fas fa-save",
      });
      buttons.push({
        uId: String.newGuid(),
        isDxActionButton: true,
        name: "cancel",
        label: "",
        type: "button",
        cssClass: "btn-danger dx-grid-record-cancel btn-icon",
        iconClass: "fas fa-times-circle",
      });

      if (this.pageData.filter.isDetailButtonActive) {
        buttons.push({
          uId: String.newGuid(),
          isDxActionButton: false,
          name: "DetailRecord",
          label: "",
          title: this.$t(
            "Components.ActionButtons.Detail",
            {},
            { locale: this.$store.state.activeLang }
          ),
          type: "link",
          routeUrl: `#/set/detail/${
            this.isLookupRelationTable
              ? this.table.lookupObjectUrlName
              : this.$route.params.key
          }`,
          appendRowPublicId: true,
          cssClass: "btn-primary dx-grid-record-detail btn-icon",
          iconClass: "fas fa-external-link-alt",
        });
      }

      if (this.pageData.filter.isEditButtonActive) {
        buttons.push({
          uId: String.newGuid(),
          isDxActionButton: false,
          name: "EditRecord",
          label: "",
          title: this.$t(
            "Components.ActionButtons.Edit",
            {},
            { locale: this.$store.state.activeLang }
          ),
          routeUrl: `#/set/edit/${
            this.isLookupRelationTable
              ? this.table.lookupObjectUrlName
              : this.$route.params.key
          }`,
          appendRowPublicId: true,
          type: "link",
          cssClass: "btn-warning dx-grid-record-edit btn-icon",
          iconClass: "fas fa-edit",
        });
      }

      if (this.pageData.filter.isDeleteButtonActive) {
        buttons.push({
          uId: String.newGuid(),
          isDxActionButton: false,
          name: "DeleteRecord",
          label: "",
          title: this.$t(
            "Components.ActionButtons.Delete",
            {},
            { locale: this.$store.state.activeLang }
          ),
          type: "button",
          cssClass: "btn-danger dx-grid-record-delete btn-icon",
          iconClass: "fas fa-trash-alt",
          attributes: {
            "data-bs-toggle": "modal",
            "data-bs-target": "#modalDelete_" + this.gridId,
          },
        });
      }

      this.filterButtons = this.pageData.filter.buttons;
      if (this.filterButtons.length > 0) {
        this.filterButtons.forEach((button) => {
          if (this.$store.state.isMultiLanguage) {
            var localizationValue =
              this.$store.getters.getLocalizationValuesByParameters({
                parentId: button.customObjectPublicId,
                itemFormulaName: button.formulaName,
                itemTypeId: 10,
              });
            if (localizationValue) {
              button.name = localizationValue.value;
            }
          }

          var buttonObj = {
            uId: button.publicId,
            isDxActionButton: false,
            name: "SetXRMCustomButton",
            title: button.name,
            type: "button",
            cssClass: "btn-light btn-icon border btn-sx-custom-button",
            iconClass: button.icon,
            iconColor: button.iconColor,
            attributes: {
              "data-name": button.name,
              "data-id": button.publicId,
              "data-formulaname": button.formulaName,
              "data-buttontypeid": button.buttonTypeId,
              "data-buttontypename": button.buttonTypeName,
              "data-businessruleid": button.businessRulePublicId,
              "data-businessrulename": button.businessRuleName,
              "data-fieldid": button.fieldPublicId,
              "data-fieldname": button.fieldName,
              "data-staticlink": button.staticLink,
              "data-customobjectid": button.customObjectPublicId,
            },
          };

          buttons.push(buttonObj);
        });
      }

      return type == 0
        ? buttons.filter(function (f) {
            return f.isDxActionButton;
          })
        : buttons.filter(function (f) {
            return !f.isDxActionButton;
          });
    },
    clickedCustomButton(customObjectKey, recordPublicId) {
      // this.grid.beginCustomLoading();
      var currentButton = this.clickedCurrentButton;
      if (currentButton.isShowApprovalMessage) {
        this.$root.buttonApproveOperationForGrid(
          this,
          currentButton,
          customObjectKey,
          recordPublicId
        );
      } else {
        this.clickedCustomButtonOperation(
          currentButton,
          customObjectKey,
          recordPublicId
        );
      }
    },
    clickedCustomButtonOperation(
      currentButton,
      customObjectKey,
      recordPublicId
    ) {
      switch (currentButton.buttonTypeId) {
        case 1:
          this.$root.businessRuleButtonTrigger(
            currentButton,
            customObjectKey,
            recordPublicId
          );
          break;
        case 2:
          this.$root.directForwardButtonTrigger(
            currentButton,
            customObjectKey,
            recordPublicId
          );
          break;
        case 3:
          var values = this.pagedItems.find(
              (f) => f[this.primaryKey] == recordPublicId
            ),
            currentField = this.pageAllFields.find(
              (f) => f.publicId == currentButton.fieldPublicId
            ),
            calculateFormulaRequest = {
              buttonPublicId: currentButton.publicId,
              customObjectKey: customObjectKey,
              recordPublicId: recordPublicId,
            },
            self = this;

          var recordValues = [];
          Object.keys(values)
            .filter(
              (f) =>
                f !== "SERIALNO" &&
                f !== this.primaryKey &&
                !f.includes(self.$fieldPublicIdFormulaExtension)
            )
            .forEach((formulaName) => {
              var value = values[formulaName],
                publicIdValue =
                  values[formulaName + self.$fieldPublicIdFormulaExtension];

              if (publicIdValue) {
                value = publicIdValue;
              }

              recordValues.push({
                key: formulaName,
                value: value,
              });
            });

          calculateFormulaRequest = {
            ...calculateFormulaRequest,
            recordValues: recordValues,
          };

          if (String.isNullOrWhiteSpace(currentField)) {
            currentField = {
              publicId: currentButton.fieldPublicId,
              formulaName: currentButton.fieldFormulaName,
            };
          }

          var fieldFormula = currentField.formulaName,
            valueObj = values[fieldFormula];

          if (String.isNullOrWhiteSpace(valueObj)) {
            var reqModel = {
              customObjectKey: customObjectKey,
              recordPublicId: recordPublicId,
              formulaNames: [fieldFormula],
            };
            this.$root.customForwardButtonTriggerNoPageDataValue(
              currentButton,
              currentField,
              reqModel,
              customObjectKey,
              recordPublicId,
              calculateFormulaRequest
            );
          } else {
            this.$root.customForwardButtonTrigger(
              currentButton,
              currentField,
              valueObj,
              customObjectKey,
              recordPublicId,
              calculateFormulaRequest
            );
          }
          break;
        case 4:
          var recordDetail = this.pagedItems.find(
            (f) => f[this.primaryKey] == recordPublicId
          );
          this.$root.formTemplateButtonTrigger(
            currentButton,
            customObjectKey,
            recordPublicId,
            recordDetail,
            this.pageAllFields
          );
          break;
        case 5:
          this.$root.businessRuleButtonTrigger(
            currentButton,
            customObjectKey,
            recordPublicId
          );
          showModal(document.getElementById("buttonModal"));
          break;
        default:
          return;
      }
    },
    getAggregates() {
      if (!this.pageData || !this.pageData.filter) return [];

      var items = [],
        self = this,
        aggregationFields = self.pageData.filter.aggregationFields;
      if (aggregationFields.length > 0) {
        aggregationFields.forEach((field) => {
          items.push({
            type: field.aggregateFunction.toLowerCase(),
            column: field.formulaName,
            showInColumn: field.formulaName,
            alignment: "left", // or "center" | "right"
            displayFormat:
              this.$t(
                "BaseModelFields." + field.aggregateFunction,
                {},
                { locale: this.$store.state.activeLang }
              ) + " {0}",
          });
        });
      }

      return items;
    },
    getDataAfterOperations(response) {
      if (this.columns.length > 0) {
        return;
      }

      if (!this.isLookupRelationTable) {
        this.$store.commit("setPageDto", response);
      }

      var isFormulaError = response.msg === this.$viewFilterFormulaErrorKey,
        isButtonValidationCalculateError =
          response.msg === this.$buttonValidationCalculateErrorKey,
        isViewFilterCriteriaRequiredError =
          response.msg === this.$viewFilterCriteriaRequiredErrorKey,
        isOk =
          response.isOk || isFormulaError || isButtonValidationCalculateError;
      if (isFormulaError) {
        this.noDataText = this.$t(
          "FieldWarnings.FormulaErrorOccurredForCriteria",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (isButtonValidationCalculateError) {
        this.noDataText = this.$t(
          "FieldWarnings.ButtonValidationCalculateError",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (isViewFilterCriteriaRequiredError && response.isOk) {
        this.noDataText = this.$t(
          "FieldWarnings.ViewFilterCriteriaRequiredError",
          {},
          { locale: this.$store.state.activeLang }
        );
      }

      if (isOk) {
        this.pageData = response;

        // this.pageData.isPageCriteriaDraw = true;

        if (this.isLookupRelationTable) {
          this.$parent.spinShow = false;
          this.$parent.isLoaded = true;
          this.$parent.isTableShow = true;
          this.$parent.tableNoPermitted = false;

          this.editSettings = {
            allowAdding:
              this.table.viewFilter.isNewButtonActive &&
              this.table.viewFilter.isEditInlineOn,
            allowEditing: this.table.viewFilter.isEditInlineOn,
          };

          this.isNotDrawAsMobile = this.table.viewFilter.isNotDrawAsMobile;
        } else {
          if (
            !this.pageData.filter ||
            (this.pageData.filter &&
              String.isNullOrWhiteSpace(this.pageData.filter.formulaName))
          ) {
            this.$router.push({
              name: "CustomObjectFilterNotFound",
              params: { key: this.$route.params.key },
            });
            return;
          }

          if (this.pageData.filter) {
            this.editSettings = {
              allowAdding:
                this.pageData.filter.isNewButtonActive &&
                this.pageData.filter.isEditInlineOn,
              allowEditing: this.pageData.filter.isEditInlineOn,
            };
            this.isNotDrawAsMobile = this.pageData.filter.isNotDrawAsMobile;
          }
        }
      } else {
        this.editSettings = {
          allowAdding: false,
          allowEditing: false,
        };
        this.responseError.push(response.msg);
        if (this.isLookupRelationTable) {
          this.$parent.spinShow = false;
          this.$parent.isLoaded = false;
          this.$parent.isTableShow = false;
          this.$parent.tableNoPermitted = true;
        } else {
          this.$root.changeDocumentTitle(
            this.$t("Components.LostWave.Title"),
            {},
            { locale: this.$store.state.activeLang }
          );
          setCurrentPageBreadcrumbs(
            this.$t(
              "Components.LostWave.Title",
              {},
              { locale: this.$store.state.activeLang }
            ),
            []
          );
        }
      }
    },
    getData() {
      var model = {},
        key = this.$route.params.key,
        isAdhocValue = this.$route.query.isAdhoc,
        isAdhoc = String.isNullOrWhiteSpace(isAdhocValue)
          ? false
          : isAdhocValue;

      if (this.isLookupRelationTable) {
        this.$parent.spinShow = true;

        model = {
          ActionName: "rws-SetController-ListRelationalRecords",
          ParentRecordPublicId: this.$route.params.id,
          LookupRelationPublicId: this.table.publicId,
          FilterId: "",
        };
      } else {
        if (String.isNullOrWhiteSpace(key)) {
          this.$router.push(this.$appHomeUrl);
          return;
        }

        var filterId = this.$route.query.filterId;
        if (String.isNullOrWhiteSpace(filterId)) {
          filterId = "";
        }

        model = {
          ActionName: "rws-SetController-List",
          Key: key,
          FilterId: filterId,
        };
      }

      if (!this.isLookupRelationTable) {
        this.$store.commit("setPageLayoutAvailable", true);
      }

      this.responseError = [];

      var self = this,
        appAxios = this.$appAxios,
        result = {};

      var loadIndex = 0;
      this.dataSource = new DataSource({
        store: new CustomStore({
          key: self.primaryKey,
          load(loadOptions) {
            self.gridDataIsLoading = true;
            var requestParameters = [];

            if (loadOptions.filter) {
              requestParameters.push({
                key: "q",
                value: loadOptions.filter[0].filterValue,
              });
              if (
                String.isNullOrWhiteSpace(loadOptions.filter[0].filterValue)
              ) {
                return;
              }
            }

            var orderFields = [],
              ordering = [];
            if (loadOptions.sort) {
              loadOptions.sort.forEach((sort, i) => {
                var field = self.pageAllFields.find(
                  (f) => f.formulaName == sort.selector
                );
                if (!String.isNullOrWhiteSpace(field)) {
                  orderFields.push(field.publicId);
                  ordering.push(!sort.desc);
                }
              });
            }

            requestParameters.push({
              key: "orderFields",
              value: orderFields.join(self.$systemSeparator),
            });
            requestParameters.push({
              key: "ordering",
              value: ordering.join(self.$systemSeparator),
            });
            requestParameters.push({
              key: "isAsc",
              value: ordering.length > 0 ? ordering[0] : false,
            });
            requestParameters.push({
              key: "page",
              value: self.grid.pageIndex() + 1,
            });
            requestParameters.push({
              key: "size",
              value: String.isNullOrWhiteSpace(loadOptions.take)
                ? self.pageSize
                : loadOptions.take,
            });

            var reqUrl = `${model.ActionName}?key=${model.Key}&filterId=${model.FilterId}&isAdhoc=${isAdhoc}`;
            if (self.isLookupRelationTable) {
              reqUrl = `${model.ActionName}?parentRecordPublicId=${model.ParentRecordPublicId}&lookupRelationPublicId=${model.LookupRelationPublicId}`;
            }

            reqUrl = String.createGetUrl(reqUrl, requestParameters);

            self.$root.createEvent("SXGRID_ON_REFRESH", {
              title: "Refresh",
              message: "Event triggered when grid refreshing",
              grid: {
                id: self.gridId,
                data: self.pageData,
                pagedItems: self.pagedItems,
              },
            });

            //TODO: #2214 - request twice when first load
            if (self.isDxGridCrudAction && loadIndex == 1) loadIndex = 2;
            if (loadIndex == 1) {
              loadIndex++;
              self.gridDataIsLoading = false;
              return Promise.resolve({
                data: self.pagedItems,
                totalCount: self.pageData.pagedItems.itemCount,
              });
            } else {
              loadIndex++;
            }

            var httpRequest = appAxios.get(reqUrl);
            return httpRequest
              .then((response) => {
                result = response.data;
                if ("isOk" in result) {
                  if (!result.isOk) {
                    self.$root.createEvent(
                      "SXGRID_GETDATA_REQUEST_UNSUCCESSFUL",
                      {
                        title: "Get Data Finally",
                        message: "Event triggered when grid catch",
                        grid: {
                          id: self.gridId,
                          data: self.pageData,
                          pagedItems: self.pagedItems,
                        },
                      }
                    );
                    self.$emit("onRequestUnSuccessful", result);
                  }
                }

                var allFields = [];
                if (result.filter) {
                  result.filter.visibleFields.forEach((filterField) => {
                    allFields.push(filterField.fieldModel);
                  });
                  if (result.filter.hiddenFields.length > 0) {
                    result.filter.hiddenFields.forEach((filterField) => {
                      allFields.push(filterField.fieldModel);
                    });
                  }
                }

                var pagedItems = result.pagedItems;
                self.pagedItems = self.$root.gridDataChange(
                  pagedItems.items,
                  allFields
                );

                return {
                  items: self.pagedItems,
                  itemCount: result.pagedItems.itemCount,
                };
              })
              .then((data) => ({
                data: data.items,
                totalCount: data.itemCount,
              }))
              .catch(function (error) {
                self.$root.createEvent("SXGRID_GETDATA_REQUEST_UNSUCCESSFUL", {
                  title: "Get Data Finally",
                  message: "Event triggered when grid catch",
                  grid: {
                    id: self.gridId,
                    data: self.pageData,
                    pagedItems: self.pagedItems,
                  },
                });
                self.$emit("onRequestUnSuccessful", error);
                self.gridDataIsLoading = false;

                //TODO: console total count error fix
                return Promise.resolve({
                  data: [],
                  totalCount: 0,
                });
              })
              .finally(() => {
                self.$root.createEvent("SXGRID_GETDATA_FINALLY", {
                  title: "Get Data Finally",
                  message: "Event triggered when grid data request ended",
                  grid: {
                    id: self.gridId,
                    data: self.pageData,
                    pagedItems: self.pagedItems,
                  },
                });
                self.$emit("onRequestFinally");
                self.gridDataIsLoading = false;
              });
          },
          insert: (values) =>
            self.sendApiCRUDRequest(0, `rws-SetController-New`, {
              values: values,
            }),
          update: (key, values) =>
            self.sendApiCRUDRequest(1, `rws-SetController-Edit`, {
              key,
              values: values,
            }),
        }),
        postProcess(data) {
          if (self.columns.length > 0) {
            return data;
          }

          self.getDataAfterOperations(result);
          self.devExpressActionButtons = self.getButtons(0);
          self.customActionButtons = self.getButtons(1);
          self.columns = self.getColumns();

          // self.aggregates = self.getAggregates();

          return data;
        },
      });
    },
    getRowLiveLookupValues(recordItem) {
      var values = [],
        self = this;
      Object.keys(recordItem).forEach(function (key) {
        if (key == self.dxSystemPrimaryKey) return;

        var field = self.pageAllFields.find((f) => f.formulaName == `${key}`),
          fieldValue =
            recordItem[`${key}${self.$fieldPublicIdFormulaExtension}`],
          value = String.isNullOrWhiteSpace(fieldValue)
            ? "" //TODO: null yerine boş ataması yapıldı
            : fieldValue.toString();
        if (
          String.isNullOrWhiteSpace(field) ||
          (!String.isNullOrWhiteSpace(field) && !field.isDataReadFromMainTable)
        )
          return;

        values.push({ key: key, value: value });
      });

      return values;
    },
    modelCreate(crudType, data) {
      var recordId = "",
        self = this,
        isNewRecord = crudType == 0,
        isEditRecord = crudType == 1;

      if (!isNewRecord && !isEditRecord) return;

      if (isNewRecord) {
        recordId = this.newRowFieldDefaultValues.recordPublicId;
      } else if (isEditRecord) {
        recordId = data.key;
      }

      var model = {
        customObjectPublicId: this.pageData.customObject.publicId,
        customObjectKey: this.$route.params.key,
        recordPublicId: recordId,
        values: [],
      };

      if (this.isLookupRelationTable) {
        model = {
          customObjectPublicId: this.table.lookupObjectPublicId,
          customObjectKey: this.table.lookupObjectUrlName,
          recordPublicId: recordId,
          values: [],
        };
      }

      var obj = data.values;
      Object.keys(obj).forEach(function (key) {
        if (key == self.dxSystemPrimaryKey) return;

        var field = self.pageAllFields.find((f) => f.formulaName == key),
          value = String.isNullOrWhiteSpace(obj[key])
            ? "" //TODO: null yerine boş ataması yapıldı
            : obj[key].toString();
        if (String.isNullOrWhiteSpace(field)) return;

        if (
          field.inputHtmlType == "file" &&
          !String.isNullOrWhiteSpace(value)
        ) {
          var onlyDocumentIds = [];
          value.split(self.$systemSeparator).forEach((element) => {
            onlyDocumentIds.push(element.split(",")[0]);
          });

          value = onlyDocumentIds.join(self.$systemSeparator);
        }

        model.values.push({
          key: key,
          value: value,
        });
      });

      //Hidden Dependency Item Value Operations
      if (this.hiddenDependenyFieldItems.length > 0) {
        this.hiddenDependenyFieldItems.forEach((hiddenField) => {
          var el = $("#" + hiddenField.id);
          if (el) {
            var elData = el.closest('.hidden[data-class="grid-item"]'),
              obj = { key: elData.data("formulaname"), value: el.val() },
              isModelValueItem = model.values.find((f) => f.key == obj.key);

            if (isModelValueItem) {
              isModelValueItem.value = obj.value;
            } else {
              model.values.push(obj);
            }
          }
        });
      }

      //TODO: #2426 fix
      var onlyLiveLookupValues = [];
      if (isEditRecord) {
        var dataItems = this.grid.getDataSource().items();
        if (dataItems.length > 0) {
          var recordItem = dataItems.find(
            (f) => f[this.primaryKey] === recordId
          );
          if (recordItem) {
            onlyLiveLookupValues = this.getRowLiveLookupValues(recordItem);
          }
        }

        if (onlyLiveLookupValues.length > 0) {
          onlyLiveLookupValues.forEach((liveLookupValueObj) => {
            if (!model.values.find((f) => f.key === liveLookupValueObj.key)) {
              model.values.push({
                key: liveLookupValueObj.key,
                value: liveLookupValueObj.value,
              });
            }
          });
        }
      }

      if (
        this.isLookupRelationTable &&
        !model.values.find((f) => f.key == this.table.fieldFormulaName)
      ) {
        //relation field value
        model.values.push({
          key: this.table.fieldFormulaName,
          value: this.$route.params.id,
        });
      }

      //TODO: #1549 fix - for default values
      if (isNewRecord) {
        model.values = model.values.filter(
          (f) => !String.isNullOrWhiteSpace(f.value)
        );
      }

      return model;
    },
    getHiddenFields() {
      if (!this.grid) return [];

      return this.grid
        .option("columns")
        .filter(
          (f) => !f.visible && !["buttons", this.primaryKey].includes(f.name)
        );
    },
    getCustomSaveOptionValue() {
      var customSaveOptionInput = $(`#${this.gridId}-CustomSaveOption`),
        customSaveOptionInputValue = customSaveOptionInput.val();

      return (
        customSaveOptionInput &&
        customSaveOptionInput.length > 0 &&
        !String.isNullOrWhiteSpace(customSaveOptionInputValue) &&
        Boolean.toBool(customSaveOptionInputValue)
      );
    },
    sendApiCRUDRequest(crudType, url, data = {}) {
      if (String.isNullOrWhiteSpace(url)) return;

      var model = this.modelCreate(crudType, data),
        self = this;

      if (this.isForcingSaveButtonClicked) {
        model.isForcingSave = true;
      }

      this.inlineInformations = [];
      this.businessRuleErrors = [];
      this.inlineErrors = [];
      this.inlineWarnings = [];

      if (this.getCustomSaveOptionValue()) {
        self.dxRowIsEditing = false;
        self.isDxRowInlineEditing = false;
        self.newRowFieldDefaultValues = null;
        self.setRadioSelectListValuesDefaultValues();

        this.$root.createEvent("SXGRID_ON_SAVE", {
          title: "Grid On Save",
          message: "Event triggered when grid inline new and editing action",
          grid: {
            id: this.gridId,
            data: this.pageData,
            pagedItems: this.pagedItems,
            customSaveModel: {
              crudType: crudType === 0 ? "New" : "Edit",
              httpRequestType: "POST",
              url,
              model,
            },
          },
        });
      } else {
        return new Promise((resolve, reject) => {
          self.$appAxios
            .post(url, model)
            .then((response) => {
              var result = response.data;

              this.isForcingSaveButtonClicked = false;
              this.isDxGridCrudAction = result.isOk;

              this.businessRuleResponse = result.businessRuleResponse;

              if (
                this.businessRuleResponse.businessRuleResponses.length > 0 &&
                this.businessRuleResponse.notificationType !== "Tooltip"
              ) {
                self.businessRuleErrors =
                  this.businessRuleResponse.businessRuleResponses;
                if (result.isOk) {
                  showModal(
                    document.getElementById(`modalInlineError_${self.gridId}`)
                  );
                }
              }

              if (!String.isNullOrWhiteSpace(result.validations)) {
                result.validations.forEach((validation) => {
                  var errorMessage = validation.message;
                  if (!String.isNullOrWhiteSpace(validation.fieldName)) {
                    errorMessage = `${validation.fieldName}: ${validation.message}`;
                  }
                  if (
                    validation.validationType &&
                    validation.validationType.value == 2
                  ) {
                    self.inlineWarnings.push({
                      message: errorMessage,
                      model: model,
                      self: self,
                      component: "Grid",
                    });
                  } else if (
                    validation.validationType &&
                    validation.validationType.value == 3
                  ) {
                    self.inlineInformations.push(validation);
                  } else {
                    self.inlineErrors.push(errorMessage);
                  }
                });
              }

              if (!String.isNullOrWhiteSpace(result.msg)) {
                self.inlineErrors.push(result.msg);
              }

              if (!String.isNullOrWhiteSpace(result.message)) {
                self.inlineErrors.push(result.message);
              }

              if (result.isOk) {
                this.inlineErrors = [];
                this.inlineWarnings = [];
              }

              var isInlineErrorModalOpen =
                self.businessRuleErrors.length > 0 ||
                self.inlineErrors.length > 1 ||
                self.inlineWarnings.length > 0 ||
                self.inlineInformations.length > 0;
              if (isInlineErrorModalOpen) {
                showModal(
                  document.getElementById(`modalInlineError_${self.gridId}`)
                );
              }

              if (!result.isOk) {
                if (isInlineErrorModalOpen) {
                  return reject(
                    self.$t(
                      "BaseModelFields.ReviewRecordErrorsFromPopup",
                      {},
                      { locale: self.$store.state.activeLang }
                    )
                  );
                } else if (self.inlineErrors.length == 1) {
                  return reject(self.inlineErrors[0]);
                }
              } else {
                self.dxRowIsEditing = false;
                self.isDxRowInlineEditing = false;

                self.newRowFieldDefaultValues = null;
                self.setRadioSelectListValuesDefaultValues();

                createToast(
                  self.$t(
                    "BaseModelFields.SuccessMessage",
                    {},
                    { locale: self.$store.state.activeLang }
                  ),
                  {
                    showIcon: true,
                    position: "top-right",
                    type: "success",
                    transition: "zoom",
                  }
                );

                if (!self.isLookupRelationTable) {
                  $(".btn-inline-close").trigger("click");
                }

                return resolve(data);
              }
            })
            .catch(function (error) {
              reject(error);
            });
        });
      }
    },
    rowDblClick(e) {
      //
    },
    cellDblClick(args) {
      if (!args.row) return;

      var field = this.pageAllFields.find(
          (f) => f.formulaName === args.column.dataField
        ),
        recordPublicId = args.row.data[this.primaryKey];

      if (
        this.dxRowIsEditing ||
        String.isNullOrWhiteSpace(field) ||
        String.isNullOrWhiteSpace(recordPublicId) ||
        !this.editSettings.allowEditing
      )
        return;

      if (!this.pageData.filter.isCellEditingEnabled) {
        if (this.editSettings.allowEditing) {
          this.onCellDoubleClicked = true;
          this.rePaintChangesOnly = false;
          setTimeout(() => {
            this.rePaintChangesOnly = true;
            this.grid.editRow(args.rowIndex);
          }, 1);
        }
        return;
      }

      if (
        !field.isEditable ||
        field.disabled ||
        field.fieldType.includes("Formula") ||
        field.fieldType.includes("AutoId") ||
        field.fieldType == "ReadOnly" ||
        field.fieldType == "Rollup"
      ) {
        var message = this.$t(
          "FieldWarnings.NoEditableField",
          {},
          { locale: this.$store.state.activeLang }
        ).replaceAll("FIELD_TYPE", field.fieldType);
        if (field.disabled) {
          message = this.$t(
            "FieldWarnings.NoEditableFieldForViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          );
        } else if (!field.isEditable) {
          message = this.$t(
            "FieldWarnings.NoEditableFieldForUpdatePage",
            {},
            { locale: this.$store.state.activeLang }
          );
        }

        Swal.fire({
          html: `<span class='fw-bold fs-5'>${message}</span>`,
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText:
            "<i class='fa fa-times'></i>" +
            this.$t(
              "BaseModelFields.Close",
              {},
              { locale: this.$store.state.activeLang }
            ),
          customClass: {
            confirmButton: "btn fw-bold btn-success",
          },
        });
        return;
      }

      this.selectedRecordCellField = field;
      this.selectedRow = args;
      this.selectedRowData = this.pagedItems.find(
        (f) => f[this.primaryKey] == recordPublicId
      );
      this.selectedRowIndex = args.rowIndex;
      this.selectedCellIndex = args.columnIndex;

      showModal(document.getElementById(this.$refs.cellEditModal.modalId));
    },
    sxScrollSetLeftValue(leftValue) {
      $(`#${this.gridId}-double-scroll-wrapper`).scrollLeft(leftValue);
    },
    removeColumnSort(e) {
      const colIndex = parseFloat(/\d+/.exec(e.fullName)[0]);
      e.component.columnOption(colIndex, "sortOrder", null);
    },
    createSortingEvent() {
      this.$root.createEvent("SXGRID_ON_SORTING", {
        title: "Sorting",
        message: "Event triggered when changing the order of columns.",
        grid: {
          id: this.gridId,
          data: this.pageData,
          pagedItems: this.pagedItems,
        },
      });
    },
    onOptionChanged(e) {
      if (e.name == "paging") {
        if (!this.isLookupRelationTable) {
          window.scrollTo(0, 0);
        }

        this.$root.createEvent("SXGRID_ON_PAGING", {
          title: "Paging",
          message: "Event triggered when switching between pages.",
          grid: {
            id: this.gridId,
            data: this.pageData,
            pagedItems: this.pagedItems,
          },
        });
      } else if (e.fullName.includes("sortOrder")) {
        if (!e.previousValue) {
          var allSortedColumns = e.component
              .getVisibleColumns()
              .filter((c) => c.sortOrder),
            allSortedColumnCount = allSortedColumns.length,
            filteredSortedColumns = allSortedColumns.filter(
              (f) => f.sortIndex > 0
            );

          if (allSortedColumnCount >= this.maxSortOrder + 1) {
            this.removeColumnSort(e);
            return;
          }

          if (allSortedColumns.length > 1) {
            var firstSortedColumn = allSortedColumns.find(
                (f) => f.sortIndex == 0
              ),
              firstField = this.pageAllFields.find(
                (f) => f.formulaName == firstSortedColumn.dataField
              ),
              isCurrentSortIsValid = true;

            if (firstField.isMultipleValue) {
              this.removeColumnSort(e);
              return;
            }

            filteredSortedColumns.forEach((sortedColumn, index, array) => {
              var sortedField = this.pageAllFields.find(
                (f) => f.formulaName == sortedColumn.dataField
              );
              if (sortedField.isMultipleValue !== firstField.isMultipleValue) {
                isCurrentSortIsValid = false;
              }

              if (index === filteredSortedColumns.length - 1) {
                if (!isCurrentSortIsValid) {
                  this.removeColumnSort(e);
                } else {
                  this.createSortingEvent();
                }
              }
            });
          } else {
            this.createSortingEvent();
          }
        }
      } else if (e.fullName == "searchPanel.text") {
        this.$root.createEvent("SXGRID_ON_SEARCH", {
          title: "Searching",
          message: "Event triggered when grid records on searching",
          grid: {
            id: this.gridId,
            data: this.pageData,
            pagedItems: this.pagedItems,
          },
        });
      } else if (e.name === "columns" && e.fullName.endsWith("width")) {
        this.configurationSxScroll();
      } else if (e.fullName == "editing.changes") {
        if (!String.isNullOrWhiteSpace(e.value)) {
          //force review validation messages
          this.onRowValidating({ newData: e.value[0].data });
        }

        var previousData =
            e.previousValue.length > 0 ? e.previousValue[0] : null,
          previousDataObj = {
            data: previousData ? previousData.data : null,
            type: previousData ? previousData.type : null,
            publicId: previousData ? previousData.key : null,
          },
          currentData = e.value.length > 0 ? e.value[0] : null,
          currentDataObj = {
            data: currentData ? currentData.data : null,
            type: currentData ? currentData.type : null,
            publicId: currentData ? currentData.key : null,
          };
        this.$root.createEvent("SXGRID_EDITING_CHANGES", {
          title: "Grid Editing Changes",
          message: "Event triggered when grid editing changes",
          grid: {
            id: this.gridId,
            data: this.pageData,
            pagedItems: this.pagedItems,
          },
          editing: {
            values: {
              previous: previousDataObj,
              current: currentDataObj,
            },
            field: this.editingField,
          },
        });
      }
    },
  },
};
</script>
