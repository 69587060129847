<template>
  <div class="col-12" :class="classes">
    <template
      v-if="
        setPageDto !== null &&
        setPageDtoIsOk &&
        ($route.meta.isListPage ||
          $route.meta.isCalendarPage ||
          $route.meta.isTreePage)
      "
    >
      <Dropdown
        routeName="list"
        :requestUrl="
          String.format(
            '/rws-ViewFiltersForAUser?customObjectPublicId={0}',
            this.setPageFilterDto.customObjectPublicId
          )
        "
        :buttonName="setPageFilterDto.name"
        buttonClasses="btn-primary"
        groupClasses="me-2"
        v-if="
          setPageFilterDto &&
          setPageFilterDto.isInstantFilterActive &&
          !$route.meta.isTreePage
        "
      />

      <Dropdown
        routeName="kanban"
        requestUrl=""
        :buttonName="
          $t(
            'Components.ActionButtons.Kanban',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        buttonIcon="bi bi-kanban"
        buttonClasses="btn-warning"
        groupClasses="me-2"
        v-if="setPageFilterDto && setPageFilterDto.isKanbanButtonActive"
      />

      <Dropdown
        routeName="calendar"
        :requestUrl="
          String.format(
            '/rws-GetCalendarViewList?customObjectPublicId={0}',
            this.setPageFilterDto.customObjectPublicId
          )
        "
        :buttonName="
          $route.meta.isCalendarPage
            ? setPageDto && setPageDto.pageDetail && setPageDto.pageDetail.name
              ? setPageDto.pageDetail.name
              : $t(
                  'Components.ActionButtons.Calendar',
                  {},
                  { locale: this.$store.state.activeLang }
                )
            : $t(
                'Components.ActionButtons.Calendar',
                {},
                { locale: this.$store.state.activeLang }
              )
        "
        buttonIcon="far fa-calendar-alt"
        buttonClasses="btn-info"
        groupClasses="me-2"
        v-if="setPageFilterDto && setPageFilterDto.isCalendarButtonActive"
      />

      <Dropdown
        routeName="map"
        requestUrl=""
        :buttonName="
          $t(
            'Components.ActionButtons.Map',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        buttonIcon="far fa-map"
        buttonClasses="btn-primary"
        groupClasses="me-2"
        v-if="setPageFilterDto && setPageFilterDto.isMapButtonActive"
      />

      <Dropdown
        routeName="tree"
        :requestUrl="
          $route.meta.isListPage
            ? String.format(
                '/rws-GetTreeViewList?customObjectPublicId={0}&viewFilterPublicId={1}',
                this.setPageFilterDto.customObjectPublicId,
                this.setPageFilterDto.publicId
              )
            : String.format(
                '/rws-GetTreeViewList?customObjectPublicId={0}',
                this.setPageFilterDto.customObjectPublicId
              )
        "
        :buttonName="
          $route.meta.isTreePage
            ? setPageDto && setPageDto.pageDetail && setPageDto.pageDetail.name
              ? setPageDto.pageDetail.name
              : $t(
                  'Components.ActionButtons.Tree',
                  {},
                  { locale: this.$store.state.activeLang }
                )
            : $t(
                'Components.ActionButtons.Tree',
                {},
                { locale: this.$store.state.activeLang }
              )
        "
        buttonIcon="fas fa-tree"
        buttonClasses="btn-primary"
        groupClasses="me-2"
        v-if="setPageFilterDto && setPageFilterDto.isTreeButtonActive"
      />

      <Dropdown
        routeName="export"
        :localData="listExportTypes"
        :buttonName="
          $t(
            'Components.ActionButtons.Export',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        downloadUrl="/Brs-ExportViewFilterFile"
        buttonIcon="fas fa-download"
        buttonClasses="btn-dark"
        groupClasses="me-2"
        v-if="
          setPageFilterDto &&
          setPageFilterDto.isExportButtonActive &&
          !$route.meta.isTreePage &&
          !$route.meta.isCalendarPage
        "
      />

      <Dropdown
        routeName="bulkAction"
        :localData="bulkActionTypes"
        :buttonName="
          $t(
            'Components.ActionButtons.BulkAction',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        buttonIcon="fas fa-cogs"
        buttonClasses="btn-danger"
        groupClasses="me-2"
        v-if="
          setPageFilterDto &&
          setPageFilterDto.isBulkProcessButtonActive &&
          !$route.meta.isTreePage &&
          !$route.meta.isCalendarPage
        "
      />

      <router-link
        v-if="
          !String.isNullOrWhiteSpace($route.query.isAdhoc) &&
          $route.query.isAdhoc &&
          $route.meta.isListPage
        "
        type="button"
        class="btn btn-danger me-2"
        :to="{
          name: 'list',
          params: {
            key: this.$route.params.key,
          },
          query: {
            filterId: this.setPageFilterDto.publicId,
          },
        }"
      >
        {{
          $t(
            "Components.ActionButtons.ClearAdhocFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>

      <router-link
        v-if="
          !String.isNullOrWhiteSpace($route.query.isAdhoc) &&
          $route.query.isAdhoc &&
          $route.meta.isTreePage
        "
        type="button"
        class="btn btn-danger me-2"
        :to="{
          name: 'tree',
          params: {
            key: this.$route.params.key,
            treeId: setPageDto.pageDetail.publicId,
          },
        }"
      >
        {{
          $t(
            "Components.ActionButtons.ClearAdhocFilter",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>

      <button
        v-if="setPageFilterDto && setPageFilterDto.isInstantFilterActive"
        type="button"
        class="btn me-2 btn-success btn-icon"
        :title="
          $t(
            'Components.ActionButtons.InstantFilter',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        data-bs-toggle="modal"
        data-bs-target="#modalInstantFilter"
      >
        <i class="fa fa-filter"></i>
      </button>

      <ToggleButton
        v-if="
          setPageFilterDto &&
          setPageFilterDto.isEditInlineButtonActive &&
          $route.meta.isListPage
        "
        :prefix="$route.meta.isListPage ? this.$listPageGridId : ''"
        classes="me-2 w-160"
        :selected="!setPageFilterDto.isEditInlineOn"
        :first="
          $t(
            'BaseModelFields.DetailUpdate',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        :second="
          $t(
            'BaseModelFields.InLineUpdate',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        @off="toggleOff"
        @on="toggleOn"
      />

      <router-link
        v-if="
          setPageFilterDto &&
          setPageFilterDto.isNewButtonActive &&
          !inlineEditOn
        "
        type="button"
        class="btn btn-success"
        :to="{
          name: 'new',
          params: { key: this.$route.params.key },
        }"
      >
        <span>
          <i class="fa fa-plus"></i>
          {{
            this.$t(
              "Set.NewFormat",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("OBJECT_NAME", setPageDto.customObject.name)
          }}
        </span>
      </router-link>
      <button
        v-else-if="
          setPageFilterDto &&
          setPageFilterDto.isNewButtonActive &&
          inlineEditOn &&
          !closeButtonShow
        "
        type="button"
        @click="addRow"
        class="btn btn-success"
      >
        <span>
          <i class="fa fa-plus"></i>
          {{
            this.$t(
              "Set.NewFormat",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("OBJECT_NAME", setPageDto.customObject.name)
          }}</span
        >
      </button>
      <button
        v-if="closeButtonShow"
        type="button"
        @click="closeInline"
        class="btn btn-danger btn-inline-close"
      >
        <span>
          <i class="fa fa-times"></i>
          {{
            $t(
              "BaseModelFields.InLineEditCancel",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </span>
      </button>
    </template>
    <div
      id="actionButtons"
      v-if="this.$route.meta.isNewPage && !windowOpenerForm"
    >
      <button @click="save" type="button" class="btn btn-success btn-save me-2">
        <span>
          <i class="fa fa-save"></i>
          {{
            $t(
              "BaseModelFields.Save",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
      <button
        @click="saveAndNew"
        type="button"
        class="btn btn-primary btn-save-and-new me-2"
      >
        <span>
          <i class="fa fa-plus"></i>
          {{
            $t(
              "BaseModelFields.SaveAndNew",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
      <button type="button" class="btn btn-danger btn-clear" @click="clear">
        <i class="fas fa-sync"></i>
        {{
          $t(
            "BaseModelFields.Clear",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </div>
    <div
      id="actionButtons"
      v-if="this.$route.meta.isNewPage && windowOpenerForm"
    >
      <button
        @click="saveAndClose"
        type="button"
        class="btn btn-primary btn-save-and-close me-2"
      >
        <span>
          <i class="fa fa-save"></i>
          {{
            $t(
              "BaseModelFields.SaveAndClose",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
      <button
        v-if="
          !String.isNullOrWhiteSpace(isSaveAndContinueButtonActive) &&
          Boolean.toBool(isSaveAndContinueButtonActive)
        "
        @click="saveAndContinue"
        type="button"
        class="btn btn-success btn-save-and-continue me-2"
      >
        <span>
          <i class="fas fa-step-forward"></i>
          {{
            $t(
              "BaseModelFields.SaveAndContinue",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
      <button type="button" class="btn btn-danger btn-clear" @click="clear">
        <i class="fas fa-sync"></i>
        {{
          $t(
            "BaseModelFields.Clear",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </div>
    <template v-if="$route.meta.isDetailPage">
      <button
        v-if="
          setPageDto &&
          setPageDto.layout &&
          setPageDto.layout.isCellEditingEnabled
        "
        type="button"
        data-bs-toggle="tooltip"
        data-bs-html="true"
        data-bs-placement="bottom"
        :data-bs-original-title="
          $t(
            'Components.ShortcutInformationModal.CellEditingEnabled',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        class="btn btn-info btn-icon me-2 cursor-default"
      >
        <i class="fas fa-star"></i>
      </button>
      <button
        v-if="
          setPageDto &&
          setPageDto.layout &&
          setPageDto.layout.isDeleteButtonActive
        "
        type="button"
        class="btn btn-danger me-2"
        data-bs-toggle="modal"
        data-bs-target="#modalDelete"
      >
        <span>
          <i class="fas fa-trash"></i>
          {{
            $t(
              "BaseModelFields.Delete",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
      <template
        v-if="
          setPageDto &&
          setPageDto.layout &&
          setPageDto.layout.isEditButtonActive
        "
      >
        <router-link
          v-if="!$isMobile()"
          type="button"
          class="btn btn-warning btn-edit me-2"
          :to="{
            name: 'edit',
            params: { key: this.$route.params.key, id: this.$route.params.id },
            query: {
              pageLayoutId: this.$route.query.pageLayoutId,
            },
          }"
        >
          <span>
            <i class="fas fa-edit"></i>
            {{
              $t(
                "BaseModelFields.Edit",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</span
          >
        </router-link>
        <button
          v-else
          type="button"
          class="btn btn-warning btn-edit me-2"
          @click="
            clickedMobileActionButtons({
              name: 'edit',
              params: {
                key: this.$route.params.key,
                id: this.$route.params.id,
              },
              query: {
                pageLayoutId: this.$route.query.pageLayoutId,
              },
            })
          "
        >
          <span>
            <i class="fas fa-edit"></i>
            {{
              $t(
                "BaseModelFields.Edit",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</span
          >
        </button>
      </template>
      <Dropdown
        v-if="
          setPageDto &&
          setPageDto.layout &&
          setPageDto.layout.isFormTemplateButtonActive
        "
        routeName="formTemplates"
        :requestUrl="
          String.format(
            '/rws-RecordFormTemplates?coKey={0}&recordPublicId={1}',
            setPageDto.customObjectKey,
            setPageDto.recordPublicId
          )
        "
        downloadUrl="/Brs-ExportController-Get?templatePublicId={0}&customObjectPublicId={1}&recordPublicId={2}"
        buttonIcon="fas fa-download"
        buttonClasses="btn-primary"
        resultType="keyValue"
        groupClasses="me-2"
      />
      <!-- <button
        type="button"
        v-if="setPageDto.isRecordCopied"
        class="btn btn-warning"
      >
        <span>
          <i class="fas fa-copy"></i>
          {{
            $t(
              "BaseModelFields.CopyRecord",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button> -->
      <button
        type="button"
        v-if="
          setPageDto &&
          setPageDto.layout &&
          setPageDto.layout.isHistoryButtonActive
        "
        class="btn btn-info"
        @click="historyOpen"
      >
        <span>
          <i class="fas fa-history"></i>
          {{
            $t(
              "BaseModelFields.History",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
      <button
        v-if="
          setPageDto &&
          setPageDto.layout &&
          setPageDto.layout.isHistoryButtonActive
        "
        type="button"
        class="btn btn-info hidden"
        id="sx_drawer_history_toggle"
      >
        <span>
          <i class="fas fa-history"></i>
          {{
            $t(
              "BaseModelFields.History",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
    </template>
    <template v-if="$route.meta.isEditPage">
      <button @click="edit" type="button" class="btn btn-success btn-edit me-2">
        <span>
          <i class="fa fa-edit"></i>
          {{
            $t(
              "BaseModelFields.Save",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </button>
      <template v-if="$isMobile()">
        <button
          type="button"
          class="btn btn-primary"
          @click="
            clickedMobileActionButtons({
              name: 'detail',
              params: {
                key: this.$route.params.key,
                id: this.$route.params.id,
              },
              query: {
                pageLayoutId: this.$route.query.pageLayoutId,
              },
            })
          "
        >
          <span>
            <i class="fa fa-file-invoice"></i>
            {{
              $t(
                "BaseModelFields.DetailButton",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</span
          >
        </button>
      </template>
      <router-link
        v-else
        type="button"
        class="btn btn-primary"
        :to="{
          name: 'detail',
          params: { key: this.$route.params.key, id: this.$route.params.id },
          query: {
            pageLayoutId: this.$route.query.pageLayoutId,
          },
        }"
      >
        <span>
          <i class="fa fa-file-invoice"></i>
          {{
            $t(
              "BaseModelFields.DetailButton",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </router-link>
    </template>
    <template v-if="$route.meta.isHistoryPage">
      <a
        :href="
          String.format(
            '#/set/detail/{0}/{1}',
            $route.params.key,
            $route.params.id
          )
        "
        type="button"
        class="btn btn-primary back-to-detail"
      >
        <span>
          <i class="fa fa-file-invoice"></i>
          {{
            $t(
              "BaseModelFields.DetailButton",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}</span
        >
      </a>
    </template>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "ActionButtons",
  props: ["classes", "isToolbar"],
  emits: [
    "methodSave",
    "methodSaveAndNew",
    "methodSaveAndClose",
    "methodSaveAndContinue",
    "methodEdit",
    "clear",
  ],
  data() {
    return {
      setPageDto: this.$store.state.setPageDto,
      setPageDtoIsOk: this.$parent.setPageDtoIsOk,
      setPageFilterDto: this.$store.getters._getSetPageFilterDto,
      isSaveAndContinueButtonActive:
        localStorage.getItem("isSaveAndContinueButtonEnabledOnWindowOpener") ||
        false,
      listViewFilters: [],
      viewFilterSearchText: "",
      inlineEditOn: false,
      closeButtonShow: false,
      windowOpenerForm: null,
      listExportTypes: [
        {
          key: 2,
          value: "Excel",
          iconClass: "far fa-file-excel fa-2x text-success",
          IsForBulkUpdate: false,
        },
        {
          key: 2,
          value: "Excel(BulkUpdate)",
          iconClass: "far fa-file-excel fa-2x text-success",
          IsForBulkUpdate: true,
        },
        {
          key: 3,
          value: "Csv",
          iconClass: "fas fa-file-csv fa-2x text-danger",
          IsForBulkUpdate: false,
        },
      ],
      bulkActionTypes: [
        {
          key: 1,
          value: this.$t(
            "Components.DropDown.BulkUpdate",
            {},
            { locale: this.$store.state.activeLang }
          ),
          attributes: {
            "data-bs-toggle": "modal",
            "data-bs-target": `#modalBulkUpdate_${this.$listPageGridId}`,
          },
          iconClass: "fas fa-edit",
        },
        // {
        //   key: 2,
        //   value: this.$t(
        //     "Components.DropDown.SMS",
        //     {},
        //     { locale: this.$store.state.activeLang }
        //   ),
        //   iconClass: "fas fa-mobile-alt",
        // },
      ],
    };
  },
  components: {},
  mounted() {
    if (
      (this.$route.meta.isListPage ||
        this.$route.meta.isCalendarPage ||
        this.$route.meta.isTreePage) &&
      this.setPageDto &&
      this.setPageFilterDto
    ) {
      this.inlineEditOn = this.setPageFilterDto.isEditInlineOn;
      if (this.$store.state.isMultiLanguage) {
        var localizationValue =
          this.$store.getters.getLocalizationValuesByParameters({
            parentId: null,
            itemFormulaName: this.setPageDto.customObject.formulaName,
            itemTypeId: 1,
          });
        if (localizationValue) {
          this.setPageDto.customObject.name = localizationValue.value;
        }

        var localizationValueForFilter =
          this.$store.getters.getLocalizationValuesByParameters({
            parentId: this.setPageDto.customObject.publicId,
            itemPublicId: this.setPageDto.filter.publicId,
            itemTypeId: 3,
          });
        if (localizationValueForFilter) {
          this.setPageDto.filter.name = localizationValueForFilter.value;
        }
      }

      //triggering store commit
      this.inlineEditSettingChange();
    }

    if (window.opener) {
      var isIgnoreLocation =
        window.opener.location.hash.includes("/set/content");
      if (!isIgnoreLocation) {
        this.windowOpenerForm = window.opener;
      }
    }
  },
  watch: {
    "$store.state.setPageDto"() {
      this.setPageDto = this.$store.state.setPageDto;
    },
    "$store.getters._getSetPageFilterDto"() {
      this.setPageFilterDto = this.$store.getters._getSetPageFilterDto;
    },
  },
  methods: {
    clickedMobileActionButtons(to) {
      this.$router.push(to);
    },
    toggleOff() {
      this.inlineEditOn = true;
      this.inlineEditSettingChange();
    },
    toggleOn() {
      this.inlineEditOn = false;
      this.inlineEditSettingChange();
    },
    closeInline() {
      if (this.$route.meta.isListPage) {
        this.closeButtonShow = false;
        $(".dx-grid-cancel-row").trigger("click");
      }
    },
    addRow() {
      if (this.$route.meta.isListPage) {
        $(".dx-grid-add-row").trigger("click");
        this.closeButtonShow = true;
      }
    },
    historyOpen() {
      $("#sx_drawer_history_toggle").trigger("click");
      this.$store.commit("setHistoryPanelVisibility", true);
    },
    inlineEditSettingChange() {
      if (this.$route.meta.isListPage) {
        var settingValue = !this.inlineEditOn ? false : true;
        this.$store.commit("setGridEditSettings", {
          allowAdding: settingValue,
          allowEditing: settingValue,
          allowSelection: settingValue,
          allowEditOnDblClick: settingValue,
        });
      }
    },
    edit() {
      this.$emit("methodEdit");
      $(".form .btn-edit").trigger("click");
    },
    save() {
      this.$emit("methodSave");
      $(".form .btn-save").trigger("click");
    },
    saveAndClose() {
      this.$emit("methodSaveAndClose");
      $(".form .btn-save-and-close").trigger("click");
    },
    saveAndContinue() {
      this.$emit("methodSaveAndContinue");
      $(".form .btn-save-and-continue").trigger("click");
    },
    saveAndNew() {
      this.$emit("methodSaveAndNew");
      $(".form .btn-save-and-new").trigger("click");
    },
    clear() {
      this.$emit("clear");
      $(".form .btn-clear").trigger("click");
    },
  },
};
</script>
