<template>
  <div
    class="modal fade"
    :id="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`${modalId}Label`"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      :class="{ 'modal-full-width': !this.$isMobile() }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${modalId}Label`">
            <i class="fa fa-filter"></i>
            {{
              $t(
                "Components.InstantFilterModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AlertBox type="warning" :messages="responseError" />
          <div class="table-responsive">
            <table class="table table-warning table-filter mb-0">
              <thead>
                <tr>
                  <th class="text-left col-md-1">
                    {{
                      $t(
                        "Components.InstantFilterModal.CriteriaNo",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th class="col-md-3">
                    <label class="required">
                      {{
                        $t(
                          "Components.InstantFilterModal.FieldName",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                  </th>
                  <th class="col-md-3">
                    <label class="required">
                      {{
                        $t(
                          "Components.InstantFilterModal.Operator",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                  </th>
                  <th class="col-md-1">
                    {{
                      $t(
                        "Components.InstantFilterModal.AsFormula",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th class="col-md-3">
                    {{
                      $t(
                        "Components.InstantFilterModal.Value",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </th>
                  <th class="text-center col-md-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr :class="{ hidden: isRowEdit }">
                  <td class="align-middle">
                    <span class="badge bg-primary rounded-pill fs-6">{{
                      this.getCriteriaNextRowNumber()
                    }}</span>
                  </td>
                  <td>
                    <Select2
                      :placeholder="
                        $t(
                          'BaseModelFields.Choose',
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      "
                      id="instantFilterField"
                      :settings="fieldSelect2Settings"
                      @select="fieldChange($event)"
                      v-model="fieldId"
                    />
                  </td>
                  <td>
                    <Select2
                      :placeholder="
                        $t(
                          'BaseModelFields.Choose',
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      "
                      id="instantFilterOperator"
                      :settings="operatorSelect2Settings"
                      @select="operatorChange($event)"
                      v-model="operatorId"
                    />
                  </td>
                  <td width="32" class="align-middle">
                    <div class="form-check form-switch form-switch-lg">
                      <input
                        ref="checkbox"
                        class="form-check-input"
                        type="checkbox"
                        @change="asFormulaCheckOnChange"
                        v-model="asFormula"
                        :disabled="
                          String.isNullOrWhiteSpace(fieldId) ||
                          String.isNullOrWhiteSpace(operatorId)
                        "
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      v-if="String.isNullOrWhiteSpace(fieldType)"
                    />
                    <div
                      :class="{ hidden: String.isNullOrWhiteSpace(fieldType) }"
                    >
                      <Select2
                        :placeholder="
                          $t(
                            'BaseModelFields.Choose',
                            {},
                            {
                              locale: this.$store.state.activeLang,
                            }
                          )
                        "
                        id="instantFilterValue"
                        v-model="valueId"
                        :class="{ hidden: fieldType !== 'select' }"
                      />
                      <div v-if="fieldType === 'DateTime'">
                        <DatePicker
                          type="datetime"
                          id="criteria-value-DateTime"
                        />
                      </div>
                      <div v-if="fieldType === 'Date'">
                        <DatePicker type="date" id="criteria-value-Date" />
                      </div>
                      <div
                        class="phone-field-block"
                        :class="{ hidden: fieldType !== 'Phone' }"
                      ></div>
                      <div
                        class="number-field-block"
                        :class="{ hidden: fieldType !== 'Number' }"
                      ></div>
                      <input
                        v-if="fieldType == 'Text'"
                        class="form-control"
                        type="text"
                        name=""
                        id="criteria-value-Text"
                      />
                    </div>
                  </td>
                  <td class="align-middle">
                    <button
                      type="button"
                      class="btn btn-primary w-100 h-41"
                      @click="add"
                    >
                      <i class="fa fa-plus"></i>
                      {{
                        $t(
                          "Components.InstantFilterModal.Add",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </button>
                  </td>
                </tr>
                <tr class="edit-row" :class="{ hidden: !isRowEdit }">
                  <td class="align-middle">
                    <span class="badge bg-primary rounded-pill fs-6">{{
                      editRow.number
                    }}</span>
                  </td>
                  <td class="align-middle">
                    <Select2
                      :placeholder="
                        $t(
                          'BaseModelFields.Choose',
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      "
                      ref="instantFilterEditField"
                      id="instantFilterEditField"
                      @select="editFieldChange($event)"
                      :settings="fieldEditSelect2Settings"
                    />
                  </td>
                  <td class="align-middle">
                    <Select2
                      :placeholder="
                        $t(
                          'BaseModelFields.Choose',
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      "
                      ref="instantFilterEditOperator"
                      id="instantFilterEditOperator"
                      :settings="operatorSelect2EditSettings"
                      @select="editFieldOperatorChange($event)"
                    />
                  </td>
                  <td width="32" class="align-middle">
                    <div class="form-check form-switch form-switch-lg">
                      <input
                        ref="checkbox"
                        class="form-check-input"
                        type="checkbox"
                        @change="asFormulaCheckOnChange"
                        v-model="editAsFormula"
                        :disabled="
                          String.isNullOrWhiteSpace(editField.id) ||
                          String.isNullOrWhiteSpace(editOperator.id)
                        "
                      />
                    </div>
                  </td>
                  <td class="align-middle">
                    <div
                      :class="{
                        hidden: String.isNullOrWhiteSpace(fieldTypeEdit),
                      }"
                    >
                      <Select2
                        :placeholder="
                          $t(
                            'BaseModelFields.Choose',
                            {},
                            {
                              locale: this.$store.state.activeLang,
                            }
                          )
                        "
                        ref="instantFilterEditValue"
                        id="instantFilterEditValue"
                        v-model="valueEditId"
                        :class="{ hidden: fieldTypeEdit !== 'select' }"
                      />
                      <div :class="{ hidden: fieldTypeEdit !== 'DateTime' }">
                        <DatePicker
                          type="datetime"
                          :value="dateTimePickerEdit"
                          id="criteria-value-edit-DateTime"
                        />
                      </div>
                      <div :class="{ hidden: fieldTypeEdit !== 'Date' }">
                        <DatePicker
                          type="date"
                          :value="datePickerEdit"
                          id="criteria-value-edit-Date"
                        />
                      </div>
                      <div
                        class="edit-phone-field-block"
                        :class="{ hidden: fieldTypeEdit !== 'Phone' }"
                      ></div>
                      <div
                        class="edit-number-field-block"
                        :class="{ hidden: fieldTypeEdit !== 'Number' }"
                      ></div>
                      <div :class="{ hidden: fieldTypeEdit !== 'Text' }">
                        <input
                          class="form-control"
                          v-model="text"
                          type="text"
                          name=""
                          id="criteria-value-edit-Text"
                        />
                      </div>
                    </div>
                    <input
                      v-if="String.isNullOrWhiteSpace(fieldTypeEdit)"
                      type="text"
                      class="form-control"
                      disabled
                    />
                  </td>
                  <td align="center" class="align-middle">
                    <button
                      type="button"
                      class="btn btn-success btn-icon me-2"
                      @click="editSave"
                    >
                      <i class="fa fa-save"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-icon"
                      @click="editClose"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p class="mt-4 mb-0" v-if="!model.criterias.length">
            <label class="required">
              {{
                $t(
                  "Components.InstantFilterModal.NoCriteria",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label
            >
          </p>
          <div class="col-lg-12 mt-4" v-else>
            <div class="table-responsive">
              <table
                class="table table-filter table-striped table-bordered table-primary table-hover mb-0"
              >
                <thead>
                  <tr>
                    <th class="text-left mw-100px">
                      {{
                        $t(
                          "Components.InstantFilterModal.CriteriaNo",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="col-md-3">
                      <label class="">
                        {{
                          $t(
                            "Components.InstantFilterModal.FieldName",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</label
                      >
                    </th>
                    <th class="col-md-3">
                      <label class="">
                        {{
                          $t(
                            "Components.InstantFilterModal.Operator",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</label
                      >
                    </th>
                    <th class="col-md-1 text-center">
                      <label class="">
                        {{
                          $t(
                            "Components.InstantFilterModal.AsFormula",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}</label
                      >
                    </th>
                    <th class="col-md-3">
                      {{
                        $t(
                          "Components.InstantFilterModal.Value",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                    <th class="text-center mw-170px">
                      {{
                        $t(
                          "BaseModelFields.Commands",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="criteria-item"
                    :data-number="item.number"
                    v-for="item in model.criterias"
                    :key="item.number"
                  >
                    <td class="align-middle">
                      <span class="badge bg-primary rounded-pill fs-6">{{
                        item.number
                      }}</span>
                    </td>
                    <td class="align-middle" :data-value="item.fieldPublicId">
                      {{ item.fieldName }}
                    </td>
                    <td
                      class="align-middle"
                      :data-value="item.operatorPublicId"
                    >
                      {{ item.operatorName }}
                    </td>
                    <td
                      class="align-middle text-center"
                      :data-value="item.asFormula"
                    >
                      <i
                        class="fa fa-check text-success fs-4"
                        v-if="item.asFormula"
                      ></i>
                      <i class="fa fa-times text-danger fs-4" v-else></i>
                    </td>
                    <td class="align-middle" :data-value="item.value">
                      <div v-if="item.value.includes($systemSeparator)">
                        <span
                          class="badge bg-primary me-2 mb-1 fs-6"
                          v-for="(value, index) in item.value.split(
                            $systemSeparator
                          )"
                          :key="value"
                        >
                          {{ item.text.split($systemSeparator)[index] }}
                        </span>
                      </div>
                      <span v-else>
                        {{ item.numberFormattedValue || item.text }}
                      </span>
                    </td>
                    <td class="align-middle" align="center">
                      <button
                        type="button"
                        class="btn btn-sm btn-warning me-1"
                        @click="edit(item.number)"
                      >
                        <i class="fa fa-edit"></i>
                        {{
                          $t(
                            "Components.InstantFilterModal.Edit",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="remove(item.number)"
                      >
                        <i class="fa fa-trash"></i>
                        {{
                          $t(
                            "Components.InstantFilterModal.Remove",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      <input
                        type="text"
                        v-model="model.criteriaExpression"
                        class="form-control fs-5"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success btn-sm btn-viewfilter-adhoc-search"
            :disabled="!model.criterias.length"
            @click="search"
          >
            <span>
              <i class="fa fa-search"></i>
              {{
                $t(
                  "BaseModelFields.Search",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </span>
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hideModal } from "@/core/helpers/dom";
import DatePicker from "@/components/devexpress/dateboxes/DatePickerByType.vue";
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
import Select2 from "vue3-select2-component";
export default {
  name: "InstantFilterModal",
  props: ["pageData"],
  components: {
    Select2,
    DatePicker,
  },
  data() {
    return {
      modalId: "modalInstantFilter",
      searchButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      numberFormat: this.$root.getNumberFormat(),
      shortDateTimeFormat: this.$root.getDateTimeFormat("short"),
      longDateTimeFormat: this.$root.getDateTimeFormat(),
      currentField: {},
      text: "",
      phone: "",
      phoneEdit: "",
      fieldType: null,
      operatorId: null,
      operator: {},
      fieldId: null,
      field: {},
      valueId: null,
      valueEditId: null,
      editOperator: {},
      editField: {},
      value: {},
      fieldOperatorDetail: {},
      operatorSelect2Settings: {
        allowClear: true,
        disabled: true,
        multiple: false,
        dropdownAutoWidth: false,
        separator: this.$systemSeparator,
        theme: "bootstrap-5",
      },
      operatorSelect2EditSettings: {
        allowClear: true,
        disabled: false,
        multiple: false,
        dropdownAutoWidth: false,
        separator: this.$systemSeparator,
        theme: "bootstrap-5",
      },
      fieldSelect2Settings: {
        allowClear: true,
        disabled: false,
        multiple: false,
        dropdownAutoWidth: false,
        separator: this.$systemSeparator,
        theme: "bootstrap-5",
      },
      fieldEditSelect2Settings: {
        allowClear: true,
        disabled: false,
        multiple: false,
        dropdownAutoWidth: false,
        separator: this.$systemSeparator,
        theme: "bootstrap-5",
      },
      fieldTypeEdit: null,
      responseError: [],
      actionName: "/Lcdp-ViewFilterAdhoc",
      model: {
        customObjectPublicId: this.pageData.customObject.publicId,
        viewFilterPublicId: this.pageData.filter.publicId,
        criteriaExpression: "",
        criterias: [],
      },
      isRowEdit: false,
      editRow: {},
      datePickerEdit: null,
      dateTimePickerEdit: null,
      asFormula: false,
      editAsFormula: false,
      oldInformations: {},
      editOldInformations: {},
    };
  },
  methods: {
    asFormulaCheckOnChange() {
      if (this.isRowEdit) {
        this.valueEditId = null;
        this.text = "";
        this.phoneEdit = "";
        this.datePickerEdit = "";
        this.dateTimePickerEdit = "";
        if (this.editAsFormula) {
          this.editOldInformations = {
            fieldType: this.fieldTypeEdit,
          };
          this.fieldTypeEdit = "Text";
        } else {
          this.fieldTypeEdit = this.editOldInformations.fieldType;
        }
      } else {
        this.value = null;
        this.valueId = null;
        this.phone = null;
        if (this.asFormula) {
          this.oldInformations = {
            fieldType: this.fieldType,
          };
          this.fieldType = "Text";
        } else {
          this.fieldType = this.oldInformations.fieldType;
        }
      }
    },
    removeCondition(mapIndex) {
      var self = this;
      var currentConds = [
        " AND {" + mapIndex + "}",
        " AND {" + mapIndex + "} ",
        "{" + mapIndex + "} AND ",
        "{" + mapIndex + "} AND",
        " OR {" + mapIndex + "}",
        " OR {" + mapIndex + "} ",
        "{" + mapIndex + "}",
        "{" + mapIndex + "} ",
      ];

      $.each(currentConds, function () {
        self.model.criteriaExpression = self.model.criteriaExpression.replace(
          this,
          ""
        );
      });
      this.criteriaIndex--;
    },
    getCriteriasMaxNumber() {
      var maxNumber = 0;
      $.each(this.model.criterias, function () {
        if (this.number > maxNumber) {
          maxNumber = this.number;
        }
      });

      return maxNumber;
    },
    getCriteriaNextRowNumber() {
      return this.getCriteriasMaxNumber() + 1;
    },
    add() {
      var fields = $("#instantFilterField"),
        operators = $("#instantFilterOperator");

      if (
        String.isNullOrWhiteSpace(fields.val()) ||
        String.isNullOrWhiteSpace(operators.val())
      ) {
        createToast(
          this.$t(
            "Components.InstantFilterModal.FieldOrOperatorNotValid",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      if (this.model.criterias.length >= 11) {
        createToast(
          this.$t(
            "Components.InstantFilterModal.MaxCriteria",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var criteriaValue = $(`#criteria-value-${this.fieldType}`).val();
      var criteriaText = $(`#criteria-value-${this.fieldType}`).val(),
        numberFormattedValue = null;
      if (this.fieldType == "select") {
        criteriaValue = $("#instantFilterValue")
          .select2("data")
          .map((u) => u.id)
          .join(this.$systemSeparator);
        criteriaText = $("#instantFilterValue")
          .select2("data")
          .map((u) => u.text)
          .join(this.$systemSeparator);
      } else if (this.fieldType == "Phone") {
        criteriaValue = $(
          `[name="criteria-value-${this.fieldType}_full_phone_number"]`
        ).val();
        criteriaText = criteriaValue;
      } else if (
        this.fieldType == "Number" &&
        !String.isNullOrWhiteSpace(criteriaValue)
      ) {
        numberFormattedValue = this.$root.priceFormattedOutput(
          criteriaValue,
          this.currentField.decimalPlaces
        );
      }

      var params = {
        number: this.getCriteriaNextRowNumber(),
        fieldType: this.fieldType,
        fieldPublicId: this.field.id,
        fieldName: this.field.text,
        operatorPublicId: this.operator.id,
        operatorName: this.operator.text,
        originalOperatorName: this.operator.originalText,
        asFormula: this.asFormula,
        value: criteriaValue,
        text: criteriaText,
        numberFormattedValue: numberFormattedValue,
      };

      var valid = true;
      $.each(this.model.criterias, function (i, v) {
        if (
          params.fieldPublicId === v.fieldPublicId &&
          params.operatorPublicId === v.operatorPublicId &&
          params.value === v.value
        ) {
          valid = false;
          return;
        }
      });
      if (!valid) {
        createToast(
          this.$t(
            "Components.InstantFilterModal.NoUnique",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.firstRowDefault();
      this.model.criterias.push(params);
      if (String.isNullOrWhiteSpace(this.model.criteriaExpression)) {
        this.model.criteriaExpression = `{${params.number}}`;
      } else {
        this.model.criteriaExpression += ` AND {${params.number}}`;
      }
    },
    edit(number) {
      this.editRow = this.model.criterias.find((f) => f.number === number);
      if (String.isNullOrWhiteSpace(this.editRow)) {
        this.isRowEdit = false;
        return;
      }

      this.editField = {
        id: this.editRow.fieldPublicId,
        text: this.editRow.fieldName,
      };

      this.editOperator = {
        id: this.editRow.operatorPublicId,
        text: this.editRow.operatorName,
        originalOperatorName: this.editRow.originalOperatorName,
      };

      this.editAsFormula = this.editRow.asFormula;

      this.isRowEdit = true;
      this.editProcess(this.editRow);
    },
    editFieldChange({ id, text }) {
      this.editField = { id: id, text: text };
      this.editValuesSetNull();
      this.getEditOperators();
    },
    getEditOperators() {
      var childField = $("#instantFilterEditOperator");
      childField.val(null);

      var parentField = $("#instantFilterEditField");
      if (String.isNullOrWhiteSpace(parentField.val())) {
        childField.select2({
          language: this.$root.getSelect2Locale(this.$store.state.activeLang),
          theme: "bootstrap-5",
          disabled: true,
          dropdownAutoWidth: false,
          placeholder: this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
        });
      } else {
        childField.select2({
          language: this.$root.getSelect2Locale(this.$store.state.activeLang),
          theme: "bootstrap-5",
          allowClear: true,
          closeOnSelect: true,
          dropdownParent: $("#modalInstantFilter"),
          maximumSelectionSize: 99,
          minimumInputLength: 0,
          dropdownAutoWidth: false,
          disabled: false,
          multiple: false,
          separator: this.$systemSeparator,
          placeholder: this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
          ajax: this.$root.getSelect2AjaxConfiguration(
            { fieldType: "NotSelectlist", isCustomMatcher: true },
            `rws-Operators?parentId=${this.editField.id}`
          ),
        });
      }
    },
    editFieldOperatorChange({ id, text, originalText }) {
      this.editOperator = { id: id, text: text, originalText: originalText };
      this.editValuesSetNull();
      this.getEditFieldOperatorDetail();
    },
    editValuesSetNull() {
      this.fieldTypeEdit = null;
      this.valueEditId = null;
      this.text = "";
      this.phoneEdit = "";
      this.datePickerEdit = "";
      this.dateTimePickerEdit = "";
    },
    getEditFieldOperatorDetail() {
      if (
        String.isNullOrWhiteSpace($("#instantFilterEditField").val()) ||
        String.isNullOrWhiteSpace($("#instantFilterEditOperator").val())
      ) {
        this.fieldTypeEdit = null;
        this.valueEditId = null;
        return;
      }
      this.$appAxios
        .get(
          `Lcdp-GetDetailForCriteria?id=${this.editField.id}&oId=${this.editOperator.id}`
        )
        .then((field) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          this.currentField = field = field.data;

          if (
            !field.isMultipleValue &&
            (field.operatorKeyName === "includes" ||
              field.operatorKeyName === "not_includes")
          ) {
            field.isMultipleValue = true;
          }

          if (
            field.operatorKeyName === "equals" ||
            field.operatorKeyName === "not_equal_to" ||
            field.operatorKeyName === "not_includes" ||
            field.operatorKeyName === "includes"
          ) {
            var requestUrl = null;
            switch (field.fieldType) {
              case "Predefined":
                requestUrl = this.$root.getFieldRequestUrl("predefined", {
                  parentId: field.predefinedPublicId,
                });
                break;
              case "OrganizationalUnit":
              case "FormulaOrganizationalUnit":
                requestUrl = this.$root.getFieldRequestUrl(
                  "organizationalunit",
                  {
                    publicId: field.publicId,
                    name: field.organizationalUnitName,
                    filterType: field.organizationalUnitFilterType,
                    groupIds: field.organizationalUnitGroupPublicIds,
                    includeItself: field.organizationalUnitIncludeItself,
                    depth: field.organizationalUnitDepth,
                    isAddCurrentKeys: true,
                  }
                );
                break;
              case "SelectList":
              case "Checkbox":
                requestUrl = this.$root.getFieldRequestUrl("select", {
                  id: field.publicId,
                });
                break;
              case "Lookup":
                requestUrl = this.$root.getFieldRequestUrl("lookup", {
                  coId: field.customObjectPublicId,
                  fieldPublicId: field.publicId,
                });
                break;
              default:
                break;
            }

            if (String.isNullOrWhiteSpace(requestUrl)) {
              this.fieldTypeEdit = "Text";
            }

            if (
              !String.isNullOrWhiteSpace(requestUrl) &&
              (field.fieldType === "SelectList" ||
                field.fieldType === "Checkbox" ||
                field.fieldType === "Lookup" ||
                field.fieldType === "Predefined" ||
                field.fieldType === "OrganizationalUnit" ||
                field.fieldType === "FormulaOrganizationalUnit")
            ) {
              this.fieldTypeEdit = "select";
              var criteriaValue = $("#instantFilterEditValue");
              criteriaValue.val(null);
              criteriaValue.select2({
                language: this.$root.getSelect2Locale(
                  this.$store.state.activeLang
                ),
                theme: "bootstrap-5",
                allowClear: true,
                closeOnSelect: !field.isMultipleValue,
                maximumSelectionSize: 99,
                minimumInputLength: 0,
                dropdownAutoWidth: false,
                dropdownParent: $("#modalInstantFilter"),
                disabled: false,
                multiple: field.isMultipleValue,
                separator: this.$systemSeparator,
                placeholder: this.$t(
                  "BaseModelFields.Choose",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                ajax: this.$root.getSelect2AjaxConfiguration(field, requestUrl),
              });
            }
          } else {
            this.fieldTypeEdit = "Text";
          }

          if (field.fieldType === "Phone") {
            this.fieldTypeEdit = "Phone";
            $(".edit-phone-field-block").html(`<input
              class="form-control sx-phone-control"
              type="text"
              name=""
              id="criteria-value-edit-Phone"
            />`);
            this.$root.preparePhoneNumber(
              "criteria-value-edit-Phone",
              null,
              document.querySelector(`#${this.modalId}`)
            );
          } else if (field.fieldType === "Number") {
            this.fieldTypeEdit = "Number";

            var maxLength = String.isNullOrWhiteSpace(
                field.inputMaskPattern.length
              )
                ? field.length
                : field.inputMaskPattern.length,
              placeholder = this.$root.inputMaskPatternReplace(field);

            $(".edit-number-field-block").html(`<input
              class="form-control"
              type="text"
              maxlength="${maxLength}"
              placeholder="${placeholder}"
              id="criteria-value-edit-Number"
            />`);

            this.$SXWindowFunctions.prepareNumberInputWithLimitedIntegerDigits(
              document.getElementById("criteria-value-edit-Number"),
              this.numberFormat.group,
              this.numberFormat.decimal,
              field.isThousandSeparator,
              field.decimalPlaces,
              field.length,
              ""
            );
          } else if (
            field.fieldType == "Date" ||
            field.fieldType == "FormulaDate" ||
            field.fieldType == "RollupDate"
          ) {
            this.fieldTypeEdit = "Date";
          } else if (
            field.fieldType == "DateTime" ||
            field.fieldType == "FormulaDateTime" ||
            field.fieldType == "RollupDateTime"
          ) {
            this.fieldTypeEdit = "DateTime";
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    editProcess(editRow) {
      var isFormula = this.editRow.asFormula;
      this.$appAxios
        .get(
          `Lcdp-GetDetailForCriteria?id=${editRow.fieldPublicId}&oId=${editRow.operatorPublicId}`
        )
        .then((result) => {
          var field = result.data;

          if (
            !field.isMultipleValue &&
            (field.operatorKeyName === "includes" ||
              field.operatorKeyName === "not_includes")
          ) {
            field.isMultipleValue = true;
          }

          if (
            field.operatorKeyName === "equals" ||
            field.operatorKeyName === "not_equal_to" ||
            field.operatorKeyName === "not_includes" ||
            field.operatorKeyName === "includes"
          ) {
            var requestUrl = null;
            switch (field.fieldType) {
              case "Predefined":
                requestUrl = this.$root.getFieldRequestUrl("predefined", {
                  parentId: field.predefinedPublicId,
                });
                break;
              case "OrganizationalUnit":
              case "FormulaOrganizationalUnit":
                requestUrl = this.$root.getFieldRequestUrl(
                  "organizationalunit",
                  {
                    publicId: field.publicId,
                    name: field.organizationalUnitName,
                    filterType: field.organizationalUnitFilterType,
                    groupIds: field.organizationalUnitGroupPublicIds,
                    includeItself: field.organizationalUnitIncludeItself,
                    depth: field.organizationalUnitDepth,
                    isAddCurrentKeys: true,
                  }
                );
                break;
              case "SelectList":
              case "Checkbox":
                requestUrl = this.$root.getFieldRequestUrl("select", {
                  id: field.publicId,
                });
                break;
              case "Lookup":
                requestUrl = this.$root.getFieldRequestUrl("lookup", {
                  coId: field.customObjectPublicId,
                  fieldPublicId: field.publicId,
                });
                break;
              default:
                break;
            }

            if (String.isNullOrWhiteSpace(requestUrl)) {
              this.fieldTypeEdit = "Text";
              this.text = editRow.value;
            }

            if (
              !String.isNullOrWhiteSpace(requestUrl) &&
              (field.fieldType === "SelectList" ||
                field.fieldType === "Checkbox" ||
                field.fieldType === "Lookup" ||
                field.fieldType === "Predefined" ||
                field.fieldType === "OrganizationalUnit" ||
                field.fieldType === "FormulaOrganizationalUnit")
            ) {
              this.fieldTypeEdit = "select";
              var criteriaValue = $("#instantFilterEditValue");
              criteriaValue.find("option").remove();
              criteriaValue.val(null);
              criteriaValue.select2({
                language: this.$root.getSelect2Locale(
                  this.$store.state.activeLang
                ),
                theme: "bootstrap-5",
                allowClear: true,
                closeOnSelect: field.isMultipleValue ? false : true,
                maximumSelectionSize: 99,
                dropdownAutoWidth: false,
                disabled: false,
                dropdownParent: $("#modalInstantFilter"),
                multiple: field.isMultipleValue,
                separator: this.$systemSeparator,
                placeholder: this.$t(
                  "BaseModelFields.Choose",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                ajax: this.$root.getSelect2AjaxConfiguration(field, requestUrl),
              });

              //set value(s)
              if (!isFormula) {
                this.editRow.value
                  .split(this.$systemSeparator)
                  .forEach((element, index) => {
                    criteriaValue
                      .append(
                        $(
                          `<option selected value="${element}">${
                            this.editRow.text.split(this.$systemSeparator)[
                              index
                            ]
                          }</option>`
                        )
                      )
                      .trigger("change");
                  });
              }
            }
          } else {
            this.fieldTypeEdit = "Text";
            this.text = editRow.value;
          }

          if (field.fieldType === "Phone") {
            this.fieldTypeEdit = "Phone";
            this.phoneEdit = editRow.value;
            $(".edit-phone-field-block").html(`<input
              class="form-control sx-phone-control"
              type="text"
              name=""
              id="criteria-value-edit-Phone"
            />`);
            this.$root.preparePhoneNumber(
              "criteria-value-edit-Phone",
              this.phoneEdit,
              document.querySelector(`#${this.modalId}`)
            );
          } else if (field.fieldType === "Number") {
            this.fieldTypeEdit = "Number";

            var maxLength = String.isNullOrWhiteSpace(
                field.inputMaskPattern.length
              )
                ? field.length
                : field.inputMaskPattern.length,
              placeholder = this.$root.inputMaskPatternReplace(field);

            $(".edit-number-field-block").html(`<input
              class="form-control"
              type="text"
              maxlength="${maxLength}"
              placeholder="${placeholder}"
              id="criteria-value-edit-Number"
            />`);

            this.$SXWindowFunctions.prepareNumberInputWithLimitedIntegerDigits(
              document.getElementById("criteria-value-edit-Number"),
              this.numberFormat.group,
              this.numberFormat.decimal,
              field.isThousandSeparator,
              field.decimalPlaces,
              field.length,
              editRow.value
            );
          } else if (
            field.fieldType == "Date" ||
            field.fieldType == "FormulaDate" ||
            field.fieldType == "RollupDate"
          ) {
            this.fieldTypeEdit = "Date";
            this.datePickerEdit = editRow.value;
          } else if (
            field.fieldType == "DateTime" ||
            field.fieldType == "FormulaDateTime" ||
            field.fieldType == "RollupDateTime"
          ) {
            this.fieldTypeEdit = "DateTime";
            this.dateTimePickerEdit = editRow.value;
          }

          if (this.editRow.asFormula) {
            this.editOldInformations = {
              fieldType: this.fieldTypeEdit,
            };
            this.text = this.editRow.value;
            this.fieldTypeEdit = "Text";
          }
        });

      $("#instantFilterEditField").select2({
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
        allowClear: true,
        disabled: false,
        multiple: false,
        dropdownAutoWidth: false,
        separator: this.$systemSeparator,
        dropdownParent: $("#modalInstantFilter"),
        theme: "bootstrap-5",
        placeholder: this.$t(
          "BaseModelFields.Choose",
          {},
          { locale: this.$store.state.activeLang }
        ),
        ajax: this.$root.getSelect2AjaxConfiguration(
          {
            fieldType: "NotSelectlist",
            customObject: this.pageData.customObject,
            isCustomMatcher: true,
          },
          `rws-FilterableFields?parentId=${this.pageData.customObject.publicId}`
        ),
      });

      $("#instantFilterEditOperator").select2({
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
        theme: "bootstrap-5",
        allowClear: true,
        disabled: false,
        maximumSelectionSize: 99,
        dropdownAutoWidth: false,
        multiple: false,
        dropdownParent: $("#modalInstantFilter"),
        separator: this.$systemSeparator,
        placeholder: this.$t(
          "BaseModelFields.Choose",
          {},
          { locale: this.$store.state.activeLang }
        ),
        ajax: this.$root.getSelect2AjaxConfiguration(
          { fieldType: "NotSelectlist" },
          `rws-Operators?parentId=${editRow.fieldPublicId}`
        ),
      });

      $(".edit-row td").css("background-color", "#ffc107");
      setTimeout(() => {
        $(".edit-row td").css("background-color", "");
      }, 1000);

      $(".criteria-item .btn").prop("disabled", false);
      $(`.criteria-item[data-number='${this.editRow.number}'] .btn`).prop(
        "disabled",
        true
      );

      this.$root.select2SetValue(
        this.$refs.instantFilterEditField.select2,
        false,
        this.editRow.fieldPublicId,
        this.editRow.fieldName,
        false
      );
      this.$root.select2SetValue(
        this.$refs.instantFilterEditOperator.select2,
        false,
        this.editRow.operatorPublicId,
        this.editRow.operatorName,
        false
      );
    },
    editSave() {
      var fields = $("#instantFilterEditField"),
        operators = $("#instantFilterEditOperator");

      if (
        String.isNullOrWhiteSpace(fields.val()) ||
        String.isNullOrWhiteSpace(operators.val())
      ) {
        createToast(
          this.$t(
            "Components.InstantFilterModal.FieldOrOperatorNotValid",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      if (this.model.criterias.length >= 11) {
        createToast(
          this.$t(
            "Components.InstantFilterModal.MaxCriteria",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var criteriaValue = $(`#criteria-value-edit-${this.fieldTypeEdit}`).val();
      var criteriaText = $(`#criteria-value-edit-${this.fieldTypeEdit}`).val(),
        numberFormattedValue = null;
      if (this.fieldTypeEdit == "select") {
        criteriaValue = $("#instantFilterEditValue")
          .select2("data")
          .map((u) => u.id)
          .join(this.$systemSeparator);
        criteriaText = $("#instantFilterEditValue")
          .select2("data")
          .map((u) => u.text)
          .join(this.$systemSeparator);
      } else if (this.fieldTypeEdit == "Phone") {
        criteriaValue = $(
          `[name="criteria-value-edit-${this.fieldTypeEdit}_full_phone_number"]`
        ).val();
        criteriaText = criteriaValue;
      } else if (
        this.fieldTypeEdit == "Number" &&
        !String.isNullOrWhiteSpace(criteriaValue)
      ) {
        numberFormattedValue = this.$root.priceFormattedOutput(
          criteriaValue,
          this.currentField.decimalPlaces
        );
      }

      this.editRow.fieldPublicId = this.editField.id;
      this.editRow.fieldName = this.editField.text;
      this.editRow.operatorPublicId = this.editOperator.id;
      this.editRow.operatorName = this.editOperator.text;
      this.editRow.originalOperatorName = this.editOperator.originalText;
      this.editRow.isEdited = true;
      this.editRow.asFormula = this.editAsFormula;
      this.editRow.value = criteriaValue;
      this.editRow.text = criteriaText;
      this.editRow.numberFormattedValue = numberFormattedValue;

      var params = this.editRow;
      var valid = true;
      $.each(this.model.criterias, function (i, v) {
        if (
          params.fieldPublicId === v.fieldPublicId &&
          params.operatorPublicId === v.operatorPublicId &&
          params.value === v.value &&
          params.number !== v.number
        ) {
          valid = false;
          return;
        }
      });
      if (!valid) {
        createToast(
          this.$t(
            "Components.InstantFilterModal.NoUnique",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: true,
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      this.editClose();
    },
    editClose() {
      // this.firstRowDefault();
      this.editRow = {};
      this.editField = {};
      this.editOperator = {};
      this.isRowEdit = false;
      this.editValuesSetNull();
      $(".criteria-item .btn").prop("disabled", false);
    },
    firstRowDefault() {
      this.phone = "";
      this.fieldType = null;
      this.operatorId = null;
      this.operator = {};
      this.fieldId = null;
      this.field = {};
      this.asFormula = false;
      this.$root.select2SetValue($("#instantFilterValue"), false);
      $("#instantFilterOperator").select2("enable", false);
    },
    remove(number) {
      this.model.criterias = this.model.criterias.filter(function (item) {
        return item.number !== number;
      });
      $(`[data-number="${number}"]`).closest("tr").remove();
      if (this.model.criterias.length == 0) {
        this.model.criteriaExpression = null;
      } else {
        this.removeCondition(number);
      }
    },
    fieldChange({ id, text }) {
      this.fieldId = id;
      this.field = { id: id, text: text };
      this.fieldType = null;
      this.getOperators();
    },
    operatorChange({ id, text, originalText }) {
      this.operatorId = id;
      this.operator = { id: id, text: text, originalText: originalText };
      this.fieldType = null;
      this.getFieldOperatorDetail();
    },
    getOperators() {
      var childField = $("#instantFilterOperator");
      childField.val(null);

      var parentField = $("#instantFilterField");
      if (String.isNullOrWhiteSpace(parentField.val())) {
        childField.select2({
          language: this.$root.getSelect2Locale(this.$store.state.activeLang),
          theme: "bootstrap-5",
          disabled: true,
          dropdownAutoWidth: false,
          placeholder: this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
        });
      } else {
        childField.select2({
          language: this.$root.getSelect2Locale(this.$store.state.activeLang),
          theme: "bootstrap-5",
          allowClear: true,
          closeOnSelect: true,
          dropdownParent: $("#modalInstantFilter"),
          maximumSelectionSize: 99,
          minimumInputLength: 0,
          dropdownAutoWidth: false,
          disabled: false,
          multiple: false,
          separator: this.$systemSeparator,
          placeholder: this.$t(
            "BaseModelFields.Choose",
            {},
            { locale: this.$store.state.activeLang }
          ),
          ajax: this.$root.getSelect2AjaxConfiguration(
            { fieldType: "NotSelectlist", isCustomMatcher: true },
            `rws-Operators?parentId=${this.field.id}`
          ),
        });
      }
    },
    getFieldOperatorDetail() {
      if (
        String.isNullOrWhiteSpace($("#instantFilterField").val()) ||
        String.isNullOrWhiteSpace($("#instantFilterOperator").val())
      ) {
        this.fieldType = null;
        this.valueId = null;
        return;
      }
      this.$appAxios
        .get(
          `Lcdp-GetDetailForCriteria?id=${this.field.id}&oId=${this.operator.id}`
        )
        .then((field) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          this.currentField = field = field.data;

          if (
            !field.isMultipleValue &&
            (field.operatorKeyName === "includes" ||
              field.operatorKeyName === "not_includes")
          ) {
            field.isMultipleValue = true;
          }

          if (
            field.operatorKeyName === "equals" ||
            field.operatorKeyName === "not_equal_to" ||
            field.operatorKeyName === "not_includes" ||
            field.operatorKeyName === "includes"
          ) {
            var requestUrl = null;
            switch (field.fieldType) {
              case "Predefined":
                requestUrl = this.$root.getFieldRequestUrl("predefined", {
                  parentId: field.predefinedPublicId,
                });
                break;
              case "OrganizationalUnit":
              case "FormulaOrganizationalUnit":
                requestUrl = this.$root.getFieldRequestUrl(
                  "organizationalunit",
                  {
                    publicId: field.publicId,
                    name: field.organizationalUnitName,
                    filterType: field.organizationalUnitFilterType,
                    groupIds: field.organizationalUnitGroupPublicIds,
                    includeItself: field.organizationalUnitIncludeItself,
                    depth: field.organizationalUnitDepth,
                    isAddCurrentKeys: true,
                  }
                );
                break;
              case "SelectList":
              case "Checkbox":
                requestUrl = this.$root.getFieldRequestUrl("select", {
                  id: field.publicId,
                });
                break;
              case "Lookup":
                requestUrl = this.$root.getFieldRequestUrl("lookup", {
                  coId: field.customObjectPublicId,
                  fieldPublicId: field.publicId,
                });
                break;
              default:
                break;
            }

            if (String.isNullOrWhiteSpace(requestUrl)) {
              this.fieldType = "Text";
            }

            if (
              !String.isNullOrWhiteSpace(requestUrl) &&
              (field.fieldType === "SelectList" ||
                field.fieldType === "Checkbox" ||
                field.fieldType === "Lookup" ||
                field.fieldType === "Predefined" ||
                field.fieldType === "OrganizationalUnit" ||
                field.fieldType === "FormulaOrganizationalUnit")
            ) {
              this.fieldType = "select";
              var criteriaValue = $("#instantFilterValue");
              criteriaValue.val(null);
              criteriaValue.select2({
                language: this.$root.getSelect2Locale(
                  this.$store.state.activeLang
                ),
                theme: "bootstrap-5",
                allowClear: true,
                closeOnSelect: !field.isMultipleValue,
                maximumSelectionSize: 99,
                minimumInputLength: 0,
                dropdownAutoWidth: false,
                dropdownParent: $("#modalInstantFilter"),
                disabled: false,
                multiple: field.isMultipleValue,
                separator: this.$systemSeparator,
                placeholder: this.$t(
                  "BaseModelFields.Choose",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
                ajax: this.$root.getSelect2AjaxConfiguration(field, requestUrl),
              });
            }
          } else {
            this.fieldType = "Text";
          }

          if (field.fieldType === "Phone") {
            this.fieldType = "Phone";
            $(".phone-field-block").html(`<input
              class="form-control sx-phone-control"
              type="text"
              name=""
              id="criteria-value-Phone"
            />`);
            this.$root.preparePhoneNumber(
              "criteria-value-Phone",
              null,
              document.querySelector(`#${this.modalId}`)
            );
          } else if (field.fieldType === "Number") {
            this.fieldType = "Number";

            var maxLength = String.isNullOrWhiteSpace(
                field.inputMaskPattern.length
              )
                ? field.length
                : field.inputMaskPattern.length,
              placeholder = this.$root.inputMaskPatternReplace(field);

            $(".number-field-block").html(`<input
              class="form-control"
              type="text"
              maxlength="${maxLength}"
              placeholder="${placeholder}"
              id="criteria-value-Number"
            />`);

            this.$SXWindowFunctions.prepareNumberInputWithLimitedIntegerDigits(
              document.getElementById("criteria-value-Number"),
              this.numberFormat.group,
              this.numberFormat.decimal,
              field.isThousandSeparator,
              field.decimalPlaces,
              field.length,
              ""
            );
          } else if (
            field.fieldType == "Date" ||
            field.fieldType == "FormulaDate" ||
            field.fieldType == "RollupDate"
          ) {
            this.fieldType = "Date";
          } else if (
            field.fieldType == "DateTime" ||
            field.fieldType == "FormulaDateTime" ||
            field.fieldType == "RollupDateTime"
          ) {
            this.fieldType = "DateTime";
          }

          if (this.asFormula) {
            this.oldInformations = {
              fieldType: this.fieldType,
            };
            this.fieldType = "Text";
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    search() {
      var button = $(".btn-viewfilter-adhoc-search"),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      button.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.searchButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.responseError = [];
      this.$appAxios
        .post(this.actionName, this.model)
        .then((response) => {
          firstSpan.show();
          loadingBlock.hide();
          button.prop("disabled", false);

          var result = response.data;
          if (result.isOk && !String.isNullOrWhiteSpace(result.publicId)) {
            hideModal(document.getElementById("modalInstantFilter"));
            if (this.$route.meta.isListPage) {
              this.$router.push({
                name: "list",
                query: {
                  filterId: result.publicId,
                  isAdhoc: true,
                },
              });
            } else if (this.$route.meta.isCalendarPage) {
              this.$router.push({
                name: "calendar",
                query: {
                  filterId: result.publicId,
                  isAdhoc: true,
                },
              });
            } else if (this.$route.meta.isTreePage) {
              this.$router.push({
                name: "tree",
                query: {
                  filterId: result.publicId,
                  isAdhoc: true,
                },
              });
            }
          } else {
            this.responseError.push(result.msg);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
  mounted() {
    $("#instantFilterField").select2({
      language: this.$root.getSelect2Locale(this.$store.state.activeLang),
      allowClear: true,
      disabled: false,
      multiple: false,
      dropdownAutoWidth: false,
      placeholder: this.$t(
        "BaseModelFields.Choose",
        {},
        { locale: this.$store.state.activeLang }
      ),
      separator: this.$systemSeparator,
      theme: "bootstrap-5",
      minimumInputLength: 0,
      dropdownParent: $("#modalInstantFilter"),
      ajax: this.$root.getSelect2AjaxConfiguration(
        {
          fieldType: "NotSelectlist",
          customObject: this.pageData.customObject,
          isCustomMatcher: true,
        },
        `rws-FilterableFields?parentId=${this.pageData.customObject.publicId}`
      ),
    });

    var adhocCriteria = this.pageData.adHocCriteria,
      adhocCriterias = adhocCriteria.criterias,
      criteriasNewArrayModel = [];
    if (this.pageData && adhocCriteria && adhocCriterias.length > 0) {
      adhocCriterias.forEach((criteria) => {
        criteriasNewArrayModel.push({
          number: criteria.number,
          fieldFormulaName: criteria.fieldFormulaName,
          fieldName: criteria.fieldName,
          fieldPublicId: criteria.fieldPublicId,
          originalOperatorName: criteria.operatorName,
          operatorName: this.$t(
            "FieldOperators." + criteria.operatorName,
            {},
            { locale: this.$store.state.activeLang }
          ),
          operatorPublicId: criteria.operatorPublicId,
          text: criteria.text,
          value: criteria.value,
          asFormula: criteria.asFormula,
        });
      });
      this.model.criterias = criteriasNewArrayModel;
      this.model.criteriaExpression = adhocCriteria.criteriaExpression;
    }
  },
};
</script>
